import React, { useEffect, useState } from "react";
import api from "../../../config/configApi";

import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";

import { Link, Redirect } from "react-router-dom";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { format, parse } from "date-fns";
import logoReduto from '../../../assets/img/logoReduto.jpg';
import logoRedutoCertificado from '../../../assets/img/assinaturaEmail.png';

import Modal from 'react-modal';

import { zonedTimeToUtc } from 'date-fns-tz';

Modal.setAppElement('#root');

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const EditarOrcamento = (props) => {

  //console.log("pdfFonts:", pdfFonts); // Log para verificar o conteúdo de pdfFonts

  pdfMake.vfs = pdfFonts;

  const idEmpresa = localStorage.getItem("empresa");

  const [cliente, setCliente] = useState("");
  //const [clientes, setClientes] = useState('');
  const [clienteOptions, setClienteOptions] = useState([]);
  const [vendedor, setVendedor] = useState("");
  const [dataAtual, setDataAtual] = useState(null);
  const [aoscuidadosde, setAoscuidadosde] = useState("");
  const [validade, setValidade] = useState("");
  const [detalhes, setDetalhes] = useState("");
  const [status, setStatus] = useState("");
  const [statusInicial, setStatusInicial] = useState("");
  const [temservico, setTemservico] = useState("");
  //const [produtos, setProdutos] = useState('');
  //const [desconto, setDesconto] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [observacaogeral, setObservacaogeral] = useState("");
  const [observacaointerna, setObservacaointerna] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [cnpjEmpresa, setCnpjEmpresa] = useState("");
  const [emailEmpresa, setEmailEmpresa] = useState("");
  const [telefoneEmpresa, setTelefoneEmpresa] = useState("");
  const [razaoSocialEmpresa, setRazaoSocialEmpresa] = useState("");

  const [servicosDaApi, setServicosDaApi] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [bancoOptions, setBancoOptions] = useState([]);

  const [dadosBancariosSelecionado, setDadosBancariosSelecionado] =
    useState("");

  // const [clienteSelecionado, setClienteSelecionado] = useState("");
  const [area_id, setAreaId] = useState("");
  const [valorServico, setValorServico] = useState("");
  // const [verificaFormaPagamento, setVerificaFormaPagamento] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  const [parcelasDb, setParcelasDb] = useState([]);
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [tipoConta, setTipoConta] = useState("");
  const [treinamentoOptions, setTreinamentoOptions] = useState([]);
  const [selectedValueTreinamentos, setSelectedValueTreinamentos] = useState('');

  //Serviços que são setados ao adicionar serviços ao orçamento
  const [servicos, setServicos] = useState({
    servico: "",
    detalhes: "",
    descricao: "",
    quant: 1,
    valor: 0,
    desconto: 0,
    subtotal: 0,
    idarea: "",
    modelo: "",
    periodo: "",
  });

  // ao ficar selecionado um serviço entra nessa lista
  const [servicoSelecionado, setServicoSelecionado] = useState({
    descricao: "",
    valor: 0,
    servico: "",
    area_id: "",
    id_servico: "",
    modelo: "",
    periodo: "",
  });

  // nessa lista mostra todos os serviços que foram adicionados ao clicar no botão adicionar serviço
  const [listaServicos, setListaServicos] = useState([]);
  const [listaServicosPDF, setListaServicosPDF] = useState([]);

  // Lista que vai juntar os serviços já existentes com

  //Objeto para setar os produtos selecionados apos clicar em adicionar serviços
  const [produtos, setProdutos] = useState({
    quantidade: 0,
    desconto: 0,
    descontoPercentual: 0,
    totalSemDesconto: 0,
    total: 0,
  });

  // objeto para guardar valores de Pagamento
  const [Pagamento, setPagamento] = useState({
    formapagamento: "",
    valorVista: 0,
    numeroparcela: 0,
    valorParcela: 0,
    totalSemDesconto: 0,
    total: 0,
    datapagamento: "",
    dadosbancarios: 0,
    datapagamentoparcelado: "",
  });

  const [retorno, setRetorno] = useState({
    type: "",
    mensagem: "",
  });

  const [parceiros, setParceiros] = useState([]);
  const [parceiroSelecionado, setParceiroSelecionado] = useState("");


  //pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const formatarMoeda = (valor) => {
    if (isNaN(valor)) {
      return "R$ 0,00"; // Retorna zero formatado como moeda brasileira
    }

    return `${valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    })}`;
  };

  const [id] = useState(props.match.params.id);
  //console.log("id Visualizar Area", id);

  const setquantidadeprodutos = (event) => {
    var qt = parseInt(event);
    produtos.quantidade = qt;
  };

  const setdescontoprodutos = (event) => {
    var desconto = parseInt(event);
    produtos.desconto = desconto;
  };

  const setvalortotalprodutos = (event) => {
    produtos.total = event;
    Pagamento.total = event;
  };

  const setnumeroparcelas = (event) => {
    var numparcela = parseInt(event);
    Pagamento.numeroparcela = numparcela;
  };

  const setparcelas = (event) => {
    var parcela = parseFloat(event);
    Pagamento.valorParcela = parcela;
  };

  const setTotalSemDesconto = (event) => {
    console.log("event", event);
    Pagamento.totalSemDesconto = parseInt(event);
    produtos.totalSemDesconto = parseInt(event);
  };

  const setDataPagamento = (event) => {
    Pagamento.datapagamento = event;
  };

  const setDataPagamentoParcelado = (event) => {
    Pagamento.datapagamentoparcelado = event;
  };

  const setDadosBancarios = (event) => {
    setDadosBancariosSelecionado(event);
    Pagamento.dadosbancarios = parseInt(event);
  };

  const setDadosBancariosParcelado = (event) => {
    setDadosBancariosSelecionado(event);
    Pagamento.dadosbancariosparcelado = parseInt(event);
  };

  const handleSelectTitulacao = (event) => {
    setStatus(event.target.value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCliente(e.target.value);
  };

  const setformapagamento = (event) => {
    Pagamento.formapagamento = event;
    setFormaPagamento(event);
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getServicos = async (areaid) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      const response = await api.get("/servicoarea/" + areaid, headers);
      if (response.data) {
        setServicosDaApi(response.data);
      } else {
        console.log("Erro: serviço não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro não encontrado");
      }
    }
  };

  const getEmpresa = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await api
      .get("/empresa/" + idEmpresa, headers)
      .then((response) => {
        if (response.data) {
          setNomeEmpresa(response.data.nome);
          setCnpjEmpresa(response.data.cnpj);
          setEmailEmpresa(response.data.email);
          setTelefoneEmpresa(response.data.telefone);
          setRazaoSocialEmpresa(response.data.razaoSocial);
        } else {
          console.log("Erro: Usuário não encontrado !");
        }
      })
      .catch((err) => {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro  não encontrado");
        }
      });
  };

  const getParceiro = async () => {
    try {
      const response = await api.get("/parceiro", headers);
      console.log("parceiro", response.data);
      setParceiros(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de vinculo2:", error);
    }
  };

  const getBanco = async (dadosBancariosSelecionado) => {
    console.log("dadosBancariosSelecionado", dadosBancariosSelecionado);
    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.get("/selecionarbanco/" + dadosBancariosSelecionado, headers)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data) {

          setBanco(response.data.banco);
          setAgencia(response.data.agencia);
          setConta(response.data.conta);
          setTipoConta(response.data.tipoconta);

        } else {
          console.log("Erro: Usuário não encontrado !");
        }

      }).catch((err) => {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro  não encontrado");
        }
      })
  }

  const handleAreaChange = (e) => {
    setValorServico("");
    setServicosDaApi([]);
    setSelectedValue('');
    setAreaId(e.target.value);
    setTreinamentoOptions([]);
    setSelectedValueTreinamentos("");
    getServicos(e.target.value);
  };

  const handleServicosSelectedChange = (e) => {
    const { name, value } = e.target;
    const servicoDaApi = servicosDaApi.find(
      (servico) => servico.id === parseInt(value)
    );

    setValorServico(value);
    setServicoSelecionado({
      descricao: servicoDaApi ? servicoDaApi.descricao : "",
      valor: servicoDaApi.valor !== undefined ? servicoDaApi.valor : "1",
      servico: servicoDaApi ? servicoDaApi.servico : "",
      area_id: area_id,
      id: servicoDaApi.id,
    });

    if (value === "48") {
      getTreinamentoPorArea();
    };

    if (value !== "48") {
      setTreinamentoOptions([]);
    }
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleChangeTreinamento = (e) => {
    setSelectedValueTreinamentos(e.target.value);
  };

  const handleServicosChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setServicos((prevServicos) => {
      let valor = name === "valor" ? value : prevServicos.valor;
      let descricao = name === "descricao" ? value : prevServicos.descricao;
      let quant = name === "quant" ? value : prevServicos.quant;
      let desconto =
        name === "desconto" ? value : parseFloat(prevServicos.desconto);
      if (desconto === undefined) {
        desconto = 0;
      }

      let subtotal = quant * valor - desconto;

      servicoSelecionado.valor = valor;
      servicoSelecionado.descricao = descricao;
      servicoSelecionado.area_id = area_id;
      servicoSelecionado.quant = quant;

      return {
        ...prevServicos,
        [name]: value,
        subtotal: subtotal,
      };
    });

    if (value === "Programa de Educação Continuada" && name === "descricao") {
      getTreinamentoPorArea();
    };
    // if (value !== "Programa de Educação Continuada" && name === "descricao") {
    //   setTreinamentoOptions([]);
    //   setSelectedValueTreinamentos("");
    // };
  };

  const getTreinamentoPorArea = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await api.post("/treinamentosPorArea", { area_id }, headers);
      console.log("treinamentosPorArea", response.data);
      setTreinamentoOptions(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de vinculo2:", error);
    }

  }

  const handlePeriodicidadeChange = (e) => {
    setServicos((prevServicos) => ({
      ...prevServicos,
      modelo: e.target.value,
    }));
    servicoSelecionado.modelo = e.target.value;
    setSelectedValue(e.target.value);
    if (e.target.value == "Avulso") {
      setSelectedPeriod("Avulso");
    }
    if (e.target.value == "Contrato") {
      setSelectedPeriod("Mensal");
    }
  };

  const handlePeriodChange = (e) => {
    setServicos((prevServicos) => ({
      ...prevServicos,
      periodo: e.target.value,
    }));
    servicoSelecionado.periodo = e.target.value;
    setSelectedPeriod(e.target.value);
  }

  // const handleAdicionarServico = (e) => {
  //     e.preventDefault();
  //     var valorComDesconto;
  //     var valorParcelaTotal;
  //     var total;

  //     //Criar um novo objeto de serviço com os dados atuais
  //     const novoServico = {
  //         servico: servicoSelecionado.servico,
  //         descricao: servicoSelecionado.descricao,
  //         quant: servicos.quant,
  //         valor: servicoSelecionado.valor,
  //         desconto: servicos.desconto,
  //         subtotal: servicos.subtotal,
  //         id_servico: servicoSelecionado.id,
  //         modelo: servicoSelecionado.modelo,
  //         area_id: servicoSelecionado.area_id
  //     };

  //     // Adicionar o novo serviço ao estado listaServicos
  //     setListaServicos([...listaServicos, novoServico]);
  //     var novaQuantidade = parseInt(produtos.quantidade) + 1;
  //     var novoTotal = produtos.totalSemDesconto + servicos.subtotal;
  //     produtos.totalSemDesconto = produtos.totalSemDesconto + servicos.subtotal;
  //     if (produtos.desconto != 0) {
  //         //console.log("diferente de zero ->  ");
  //         valorComDesconto = (produtos.desconto / 100) * produtos.totalSemDesconto;
  //         produtos.total = (produtos.totalSemDesconto - valorComDesconto);
  //         Pagamento.total = total;
  //         Pagamento.valorVista = total;
  //         Pagamento.formapagamento = formaPagamento;
  //         Pagamento.totalSemDesconto = total;

  //         if (Pagamento.numeroparcela != 0) {
  //             valorParcelaTotal = total / Pagamento.numeroparcela;
  //             Pagamento.valorParcela = valorParcelaTotal;
  //         }
  //         setProdutos((prevProdutos) => ({
  //             ...prevProdutos,
  //             quantidade: novaQuantidade,
  //             total: produtos.total,
  //             totalSemDesconto: novoTotal,
  //         }));
  //     } else {
  //         console.log("igual a zero ->  ");
  //         valorComDesconto = 0;
  //         Pagamento.total = novoTotal;
  //         Pagamento.valorVista = novoTotal;
  //         Pagamento.formapagamento = formaPagamento;
  //         Pagamento.totalSemDesconto = novoTotal;

  //         if (Pagamento.numeroparcela != 0) {
  //             valorParcelaTotal = novoTotal / Pagamento.numeroparcela;
  //             Pagamento.valorParcela = valorParcelaTotal;
  //         }

  //         setProdutos((prevProdutos) => ({
  //             ...prevProdutos,
  //             quantidade: novaQuantidade,
  //             totalSemDesconto: novoTotal,
  //             total: novoTotal,
  //         }));
  //     }

  //     console.log("Adicionar Serviços ->  ");
  //     console.log("total ->  ", total);
  //     console.log("novoTotal ->  ", novoTotal);

  //     // Atualizar o estado de produtos com base nos serviços
  //     // setProdutos((prevProdutos) => {
  //     //         ...prevProdutos,
  //     //         quantidade: novaQuantidade,
  //     //         total: total,
  //     //         totalSemDesconto: novoTotal,

  //     // });

  //     // Limpar os campos do formulário de serviço
  //     setSelectedValue('');
  //     setServicos({
  //         servico: "",
  //         descricao: "",
  //         quant: 0,
  //         valor: 0,
  //         desconto: 0,
  //         subtotal: 0,
  //         modelo: ""
  //     });

  //     console.log("Adicionar Serviços ->  ");
  //     console.log("produtos ->  ", produtos);
  //     console.log("Pagamento ->  ", Pagamento);

  //     // console.log("listaServicos FIM", listaServicos);
  //     // console.log("produtos FIM", produtos);

  // };

  const convertImageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erro ao converter imagem para base64:", error);
      return null;
    }
  };

  const handleGeneratePDF = async () => {
    var logoParceiro = undefined;
    const clienteSelecionado = clienteOptions.find(
      (clienteOptions) => clienteOptions.id == cliente
    );
    var dataAtualConvertida = format(parse(dataAtual, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
    if (formaPagamento === "avista") {
      var dataPagamentoConvertido = format(parse(Pagamento.datapagamento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
    }
    console.log("Cliente", clienteSelecionado);
    console.log("listaServicos", listaServicosPDF);
    const clienteInfo = clienteSelecionado.tipoCliente === "PL"
      ? `CPF: ${clienteSelecionado.cpf ? `${clienteSelecionado.cpf}\n` : "\n"}`
      : `CNPJ: ${clienteSelecionado.cnpj ? `${clienteSelecionado.cnpj}\n` : "\n"}`;
    //
    var converteValorTSDesconto = parseFloat(produtos.totalSemDesconto);

    const parceiroSelect = parceiros.find((parc) => parc.id == parceiroSelecionado);
    console.log("cliente", cliente);
    console.log("parceiroSelect", parceiroSelect);
    //var logoParceiro = parceiroSelect.endImagem;

    if (parceiroSelect != undefined) {
       logoParceiro = await convertImageToBase64(parceiroSelect.endImagem);
    }
    
    var logoCertificado = await convertImageToBase64(logoRedutoCertificado);

    console.log("logoParceiro", logoParceiro);
    console.log("logoCertificado", logoCertificado);

    var valorTSDesconto = `${converteValorTSDesconto.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;

    var valorDesconto = produtos.desconto;

    var converteValorProdutosTotal = parseFloat(produtos.total);

    var valorProdutosTotal = `${converteValorProdutosTotal.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;

    var valorNumeroParcela = Pagamento.numeroparcela;

    var converteValorParcelaPagamento = parseFloat(Pagamento.valorParcela);

    var valorParcelaPagamento = `${converteValorParcelaPagamento.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;

    const headerColumns = [
      {
        image: logoCertificado,
        width: 120,
        margin: [50, 20, 0, 0],
      },
    ];

    if (logoParceiro) {
      headerColumns.push({
        image: logoParceiro,
        width: 45,
        margin: [380, 25, 0, 0],
      });
    }

    const documentDefinition = {
      content: [
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 4,
              lineColor: "#0e5a5c",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        // {
        //   text: "Reduto Íon",
        //   style: "header",
        // },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `${nomeEmpresa}\n`,
                `Cnpj: ${cnpjEmpresa}\n`,
                `Email: ${emailEmpresa}\n`,
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Tel: ${telefoneEmpresa}\n`, `${razaoSocialEmpresa}\n`],
            },
          ],
        },
        {
          text: `Orçamento N° ${id}\n`,
          style: "subheaderCustom",
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `Data:\n ${dataAtualConvertida}\n`],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Vendedor:\n ${vendedor}\n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                {
                  text: [
                    `Para: \n`,
                    `${clienteSelecionado.nome}\n`,
                    `${clienteSelecionado.endereco ? `${clienteSelecionado.endereco}\n` : "\n"}`,
                    `Tel: ${clienteSelecionado.telefone ? `${clienteSelecionado.telefone}\n` : "\n"}`,
                    `Cep: ${clienteSelecionado.cep ? `${clienteSelecionado.cep}\n` : "\n"}`,
                    clienteInfo,
                    `Aos cuidados de: ${aoscuidadosde ? `${aoscuidadosde}\n` : "\n"}`,
                  ],
                },
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Validade:\n ${validade} dias \n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          text: "Serviços",
          style: "subheader",
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 'auto', 45, 'auto'], // Ajuste os valores conforme necessário
            body: [
              [
                "Serviço",
                "Descrição",
                "Área",
                "Quantidade",
                "Valor",
                "Subtotal",
              ],
              ...(listaServicosPDF.length > 0
                ? listaServicosPDF.map((servicos) => [
                  servicos.servico,
                  servicos.descricao,
                  areaOptions.find((item) => item.id == servicos.idarea).area,
                  servicos.quantidade,
                  `R$ ${servicos.valor}`,
                  `R$ ${servicos.subtotal}`,
                ])
                : []),
            ],
          },
        },
        {
          // Verifica se detalhes não é vazio nem indefinido
          ... (detalhes !== '' && detalhes !== undefined) ?
            {
              canvas: [
                {
                  type: "line",
                  x1: 0,
                  y1: 0,
                  x2: 510,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: "#000000",
                },
              ],
              margin: [0, 10, 0, 10],
            }
            : null, // Se detalhes for vazio ou indefinido, não mostra nada
        },
        {
          ... (detalhes !== '' && detalhes !== undefined) ? {
            text: "Detalhes",
            style: "subheader",
          }
            : null,
        },
        {
          text: detalhes || '', // Se detalhes for vazio ou indefinido, exibe uma string vazia
          alignment: 'justify' // ou 'left', 'right', 'center'
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        formaPagamento === "avista" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "À vista\n",
              { text: "Total sem desconto: ", bold: true }, `${valorTSDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${valorDesconto}%\n`,
              { text: "Total: ", bold: true }, `${valorProdutosTotal}\n`,
              { text: "Data de pagamento: ", bold: true }, `${dataPagamentoConvertido}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        formaPagamento === "parcelado" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "parcelado\n",
              { text: "Total sem desconto: ", bold: true }, `${valorTSDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${valorDesconto}%\n`,
              { text: "Total: ", bold: true }, `${valorProdutosTotal}\n`,
              { text: "Parcelas: ", bold: true }, `${valorNumeroParcela}\n`,
              { text: "Valor parcela: ", bold: true }, `${valorParcelaPagamento}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        formaPagamento === "parcelado" ? [
          {
            text: "Parcelas",
            style: "subheader",
          },
          {
            table: {
              body: [
                ["Data de pagamento", "Valor da parcela"],
                ...parcelasDb.map((parcelas) => [
                  parcelas.datapagamento,
                  `${valorParcelaPagamento}`,
                ]),
              ],
            },
          },
        ] : [],
        {
          style: "normalText",
          text: [
            { text: "Dados bancários: ", bold: true }, `\n`,
            { text: "Banco: ", bold: true }, `${banco}\n`,
            { text: "Agência: ", bold: true }, `${agencia}\n`,
            { text: "Número da conta: ", bold: true }, `${conta}\n`,
            { text: "Tipo da conta: ", bold: true },
            `${tipoConta === "contacorrente" ? "Conta corrente" : tipoConta}\n`,
          ]
        },
        // {
        //   text: " ",
        //   style: "subheader",
        //   margin: [0, 40, 0, 5],
        // },
        { text: '', pageBreak: 'after' },
        {
          width: "100%",
          text: [
            { text: 'Condições Comerciais:', bold: true, alignment: 'justify' }, // Título em negrito e justificado
            '\n', // Espaço após o título
            // Parágrafos justificados
            { text: "Validade do Orçamento: Este orçamento é válido por até 15 dias a partir da data de emissão.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Prazo de Entrega e Execução dos Serviços: O prazo de entrega ou execução dos serviços é de 30 dias a partir da aprovação da proposta, desde que dentro do período de validade. Os relatórios disponibilizados na plataforma online serão entregues em até 7 dias úteis após a execução das medições in loco.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Pagamento: O pagamento deverá ser realizado em até 7 dias úteis a partir do encerramento do atendimento in loco. O preço listado inclui os tributos vigentes na data da proposta. Em caso de não aceitação da proposta após visita para levantamento de dados, a taxa de visita será faturada.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Revisão de Preços e Tributos: Reservamo-nos o direito de oferecer alternativas técnicas que possam implicar em alteração dos valores. Qualquer alteração nos tributos vigentes resultará na revisão do preço ofertado.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Mora e Multa por Atraso de Pagamento: O atraso no pagamento incorrerá em juros de mora de 1% ao mês, multa moratória de 10%, além de indenização pré-fixada com base na variação do CDI entre a data de vencimento e a de efetivo pagamento.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Cobrança Judicial ou Extrajudicial: Em caso de cobrança judicial ou extrajudicial, o cliente será responsável por reembolsar todas as perdas e danos, incluindo despesas decorrentes das medidas de cobrança.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Responsabilidade do Cliente e da Contratada:\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente é responsável por garantir o acesso da Contratada às suas instalações e equipamentos necessários para a realização dos serviços contratados.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente assume total responsabilidade por falhas apresentadas pelos equipamentos durante as aferições, não cabendo à Contratada o conserto de peças danificadas. A Contratada não realiza reparos em equipamentos submetidos a testes e aferições.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "A Contratada é legalmente responsável pelos relatórios emitidos, garantindo o uso de padrões e medidores internacionalmente aceitos conforme as normativas e legislações aplicáveis em âmbito nacional.\n", margin: [0, 5, 0, 0], alignment: 'justify' }
          ],
          style: "observaoText",
        },
        (temservico && temservico.some(servico => servico.idarea == "1")) ? [
          {
            width: "100%",
            stack: [
              {
                text: [
                  { text: 'Observações Técnicas:', bold: true }, // Adiciona negrito apenas ao título
                  '\n', // Adiciona um espaço para separar o título do texto
                  "Os resultados dos testes serão apresentados em forma de relatórios, com os dados dos equipamentos, das instalações, resultados e conclusões.\n" +
                  "Os referidos relatórios terão validade de acordo com a legislação atual RDC 611 e suas respectivas Instruções Normativas (IN 90 – IN 97).\n" +
                  "O valor proposto é para realização dos testes em visita única, em caso de revisita para testes em equipamentos que no dia agendado não estavam aptos para o funcionamento, ou para novos testes em itens que não estavam satisfatórios, um novo orçamento deverá ser solicitado.\n" +
                  "Para a execução dos testes, pedimos que cada sala seja reservada durante 1h30.\n" +
                  "O sistema utilizado para gerar as imagens radiográficas do serviço deve estar em funcionamento para que sejam avaliadas.\n" +
                  "Para a realização dos testes é necessário a presença de um técnico de Raios X, que esteja habituado com as rotinas de exames da instituição, para orientar e acompanhar os serviços.\n" +
                  "Para testes em tomografia computadorizada é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "Para testes em ressonância magnética nuclear é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "As importâncias discriminadas já incluem os gastos de locomoção (ida e volta) entre nosso estabelecimento e as instalações onde se encontram os equipamentos.\n" +
                  "Caso os serviços não sejam realizados por culpa exclusiva da CONTRATANTE, será cobrada uma taxa de 20% do valor do serviço para fins de ressarcimento dos custos de deslocamento do físico.",
                ],
                margin: [0, 5], // Ajusta a margem superior para evitar espaçamento extra
                alignment: 'justify', // Justifica o texto
              },
            ],
            style: "observaoText",
          },
        ] : [
          {
            width: "100%",
            text: observacaogeral,
          },
        ],
        {
          text: " ",
          style: "subheader",
          margin: [0, 40, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              stack: [
                {
                  text: "Contratante",
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: `${clienteSelecionado.nome}\n`,
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "Empresa",
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
                {
                  text: `${nomeEmpresa}\n`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
              ],
            },
          ],
        }
      ],
      header: {
        columns: headerColumns, // Usa o array dinamicamente criado
      },
      footer: function (currentPage, pageCount) {
        let footerContent = {
          margin: [40, 0, 40, 30], // Ajuste as margens conforme necessário
          stack: []
        };

        // Se não for a última página, mostre a linha com o número da página
        //if (currentPage !== pageCount) {
        footerContent.stack.push(
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 510, // Ajuste o comprimento da linha conforme necessário
                y2: 0,
                lineWidth: 2,
                lineColor: "#0e5a5c",
              },
            ],
            margin: [0, 0, 0, 10], // Ajuste as margens para posicionar a linha
            // Número da página
          },
          {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'right',
            fontSize: 9, // Ajuste o tamanho da fonte conforme necessário
            margin: [0, 10, 0, 0] // Margem superior para o número da página
          }
        );
        //}

        return footerContent;
      },
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
          color: "#0e5a5c",
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        subheaderCustom: {
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "#0e5a5c",
        },
        normalText: {
          fontSize: 12,
          margin: [0, 10, 0, 5],
        },
        observaoText: {
          fontSize: 10,
          margin: [0, 10, 0, 5],
        },
      },
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  const calcularDesconto = (totalSemDesconto, desconto) => {
    const valorComDesconto = (desconto / 100) * totalSemDesconto;
    let total = parseFloat(totalSemDesconto) - parseFloat(valorComDesconto);

    total = total.toFixed(2);
    return { total: parseFloat(total) };
  };

  const atualizarPagamento = (total, formaPagamento, datapagamento, datapagamentoparcelado, numeroparcela, novoTotalSemDesconto) => {
    const Pagamento = {
      datapagamento: datapagamento,
      total,
      valorVista: total,
      formapagamento: formaPagamento,
      totalSemDesconto: novoTotalSemDesconto,
      valorParcela: numeroparcela !== 0 ? total / numeroparcela : 0,
      numeroparcela,
      datapagamentoparcelado,
    };

    return Pagamento;
  };

  const atualizarPagamentoComDesconto = (total, formaPagamento, datapagamento, datapagamentoparcelado, numeroparcela, novoTotalSemDesconto) => {

    const Pagamento = {
      datapagamento: datapagamento,
      total,
      valorVista: total,
      formapagamento: formaPagamento,
      totalSemDesconto: novoTotalSemDesconto,
      valorParcela: numeroparcela !== 0 ? total / numeroparcela : 0,
      numeroparcela,
      datapagamentoparcelado,
    };

    return Pagamento;
  };

  const atualizarPagamentoSemDesconto = (total, formaPagamento, datapagamento, datapagamentoparcelado, numeroparcela) => {

    const Pagamento = {
      datapagamento: datapagamento,
      total,
      valorVista: total,
      formapagamento: formaPagamento,
      totalSemDesconto: total,
      valorParcela: numeroparcela !== 0 ? total / numeroparcela : 0,
      numeroparcela,
      datapagamentoparcelado,
    };

    return Pagamento;
  };

  const handleAdicionarServico = (e) => {

    console.log("servicos.quant", servicos.quant);
    console.log("servicoSelecionado", servicoSelecionado);
    const camposObrigatorios = [
      { nome: "Área", valor: area_id },
      { nome: "Serviço", valor: servicoSelecionado.servico },
      { nome: "Descrição", valor: servicoSelecionado.descricao },
      { nome: "Quantidade", valor: servicos.quant },
      { nome: "Valor", valor: servicoSelecionado.valor },
      { nome: "Modelo", valor: servicoSelecionado.modelo },
    ];

    const camposFaltando = camposObrigatorios.filter(campo =>
      campo.valor === undefined || campo.valor === "" || campo.valor === null
    );

    if (camposFaltando.length > 0) {
      const mensagemErro = camposFaltando.map(campo => `${campo.nome} é obrigatório.`).join("\n");
      if (!area_id || !servicoSelecionado.descricao || !servicoSelecionado.servico || !servicos.quant || !servicoSelecionado.valor || !servicoSelecionado.modelo) {
        let missingFields = [];

        if (!servicoSelecionado.servico) missingFields.push("Serviço");
        if (!servicoSelecionado.descricao) missingFields.push("Descrição");
        if (!servicos.quant) missingFields.push("Quantidade");
        if (!servicoSelecionado.valor) missingFields.push("Valor");
        if (!servicoSelecionado.modelo) missingFields.push("Modelo");
        if (!area_id) missingFields.push("Área");
        if (servicoSelecionado.descricao === "Programa de Educação Continuada" && !selectedValueTreinamentos) missingFields.push("Treinamento");


        setModalMessage(`Os seguintes campos estão faltando: ${missingFields.join(", ")}`);
        setIsModalOpen(true);
        return;
      }
      return;
    }
    e.preventDefault();

    var treinamento = treinamentoOptions.find((treinamentos) => treinamentos.id == selectedValueTreinamentos);

    if (treinamento === undefined) {
      treinamento = null;
    }


    e.preventDefault();
    setListaServicosPDF([])
    const novoServico = {
      servico: servicoSelecionado.servico,
      descricao: servicoSelecionado.descricao,
      quantidade: servicos.quant,
      quant: servicos.quant,
      valor: servicoSelecionado.valor,
      desconto: servicos.desconto,
      subtotal: servicos.subtotal,
      id_servico: valorServico,
      modelo: servicoSelecionado.modelo,
      idarea: area_id,
      periodo: selectedPeriod,
      treinamentoId: selectedValueTreinamentos,
      treinamento: treinamento
    };

    setListaServicos([...listaServicos, novoServico]);
    if (listaServicos.length > 0) {
      setListaServicosPDF([...temservico, ...listaServicos, novoServico]);
    };
    if (listaServicos.length === 0) {
      // Verifica se temservico é nulo e, se for, define como array vazio
      setListaServicosPDF([...(temservico || []), novoServico]);
    }

    const novaQuantidade = parseInt(produtos.quantidade) + 1;
    const novoTotalSemDesconto = produtos.totalSemDesconto + servicos.subtotal;

    //Pagamento.datapagamentoparcelado;

    let novoPagamento = { ...Pagamento }; // Fazer uma cópia para evitar sobrescrever

    if (produtos.desconto !== 0) {
      const { total } = calcularDesconto(
        novoTotalSemDesconto,
        produtos.desconto
      );
      novoPagamento = atualizarPagamentoComDesconto(
        total,
        formaPagamento,
        Pagamento.datapagamento,
        Pagamento.datapagamentoparcelado,
        novoPagamento.numeroparcela || 0,
        novoTotalSemDesconto
      );
    } else {
      novoPagamento = atualizarPagamentoSemDesconto(
        novoTotalSemDesconto,
        formaPagamento,
        Pagamento.datapagamento,
        Pagamento.datapagamentoparcelado,
        novoPagamento.numeroparcela || 0,
      );
    }

    setProdutos((prevProdutos) => ({
      ...prevProdutos,
      quantidade: novaQuantidade,
      total:
        produtos.desconto !== 0 ? novoPagamento.total : novoTotalSemDesconto,
      totalSemDesconto: novoTotalSemDesconto,
    }));

    setPagamento(novoPagamento);

    // Limpar os campos do formulário de serviço
    setAreaId("");
    setValorServico("");
    setSelectedValue("");
    setSelectedValueTreinamentos("");
    setTreinamentoOptions([]);
    setSelectedPeriod('Mensal');
    setServicos({
      servico: "",
      descricao: "",
      quant: 1,
      valor: 0,
      desconto: 0,
      subtotal: 0,
      modelo: "",
      periodo: "Mensal",
    });

    setServicoSelecionado({
      descricao: "",
      valor: 0,
      servico: "",
      area_id: "",
      id_servico: "",
      modelo: "",
      periodo: "Mensal"
    })

    console.log("Adicionar Serviços -> ");
    console.log("produtos ->  ", produtos);
    console.log("Pagamento ->  ", Pagamento);
  };

  const handleExcluirServico = (index) => {
    console.log("listaServicos -> ", listaServicos);
    const novaListaServicos = [...listaServicos];
    const servicoRemovido = novaListaServicos.splice(index, 1)[0];
    // const obj = [];

    setListaServicos([]);
    setListaServicosPDF([...(temservico || []), ...novaListaServicos]);

    const produtossemdesconto =
      produtos.totalSemDesconto - servicoRemovido.subtotal;

    let produtoscomdesconto = 0;
    if (produtos.desconto !== 0) {
      produtoscomdesconto = (produtos.desconto / 100) * produtossemdesconto;
    }

    const total = produtossemdesconto - produtoscomdesconto;

    let valorParcelaTotal = 0;
    if (Pagamento.numeroparcela !== 0) {
      valorParcelaTotal = total / Pagamento.numeroparcela;
    }

    // console.log("valorParcelaTotal -> ", valorParcelaTotal);
    // console.log("Pagamento.numeroparcela -> ", Pagamento.numeroparcela);
    // console.log("total -> ", total);

    setListaServicos(novaListaServicos);

    setProdutos((prevProdutos) => ({
      ...prevProdutos,
      quantidade: prevProdutos.quantidade - 1,
      total: total,
      totalSemDesconto: produtossemdesconto,
    }));

    setPagamento({
      total: total,
      totalSemDesconto:
        produtos.desconto !== 0
          ? produtossemdesconto - produtoscomdesconto
          : produtossemdesconto,
      numeroparcela: Pagamento.numeroparcela,
      formapagamento: Pagamento.formapagamento,
      valorVista: total,
      valorParcela: valorParcelaTotal,
      datapagamento: Pagamento.datapagamento,
      dadosbancarios: Pagamento.dadosbancarios,
      datapagamentoparcelado: Pagamento.datapagamentoparcelado,
    });
  };

  const handleData = (date) => {
    // Atualizar o estado com a data corrigida
    setDataAtual(date);
  };

  const handleDescontoChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let total;

    setProdutos((prevProdutos) => {

      console.log("prevProdutos", prevProdutos);
      const desconto =
        name === "desconto"
          ? parseFloat(value)
          : parseFloat(prevProdutos.desconto);

      // if (isNaN(desconto)) {
      //   // Se o desconto não for um número válido ou for negativo, mantenha os dados iguais
      //   total = prevProdutos.totalSemDesconto;
      // }
      if (desconto < 0) {
        total = prevProdutos.totalSemDesconto;
      }
      if (desconto > 0) {
        const valorDoDesconto =
          desconto !== 0 ? (desconto / 100) * prevProdutos.totalSemDesconto : 0;
        total = prevProdutos.totalSemDesconto - valorDoDesconto;
      }
      if (value === "" || desconto === 0 || value === null || isNaN(desconto)) {
        total = prevProdutos.totalSemDesconto;
      }
      if (formaPagamento === "parcelado") {
        if (
          !isNaN(Pagamento.numeroparcela) &&
          Pagamento.numeroparcela !== 0
        ) {
          // Apenas recalcule o pagamento se o valor for um número válido e diferente de zero
          const valorParcela = total / parseInt(Pagamento.numeroparcela);

          setPagamento((prevPagamento) => ({
            ...prevPagamento,
            total: total,
            valorVista: total,
            valorParcela: valorParcela,
          }));
        }
      }
      if (formaPagamento === "avista") {
        {
          setPagamento((prevPagamento) => ({
            ...prevPagamento,
            total: total,
            valorVista: total,
            valorParcela: Pagamento.numeroparcela,
          }));
        }
      }


      return {
        ...prevProdutos,
        [name]: value,
        total: total,
      };
    });
  };

  const handleFormaPagamentoChange = (e) => {
    setFormaPagamento(e.target.value);
    if (e.target.value === "avista") {
      setPagamento({
        formapagamento: e.target.value,
        valorVista: Pagamento ? produtos.total : "",
        numeroparcela: 0,
        total: Pagamento ? produtos.total : "",
        valorParcela: 0,
        dadosbancarios: dadosBancariosSelecionado,
      });
      setParcelasDb([]);
    }
    if (e.target.value === "parcelado") {
      setPagamento({
        formapagamento: formaPagamento,
        valorVista: 0,
        numeroparcela: 0,
        total: Pagamento ? produtos.total : "",
        valorParcela: 0,
        totalSemDesconto: Pagamento ? produtos.totalSemDesconto : "",
      });
    }
  };

  const handlePagamentoParcela = (e) => {
    //console.log("e entrou", e.target.value);
    const { name, value } = e.target;
    console.log("value", value);
    console.log("name", name);

    let nparcela = parseInt(value);

    if (name === "numeroparcela" && nparcela > 0) {
      const numeroparcela = name;
      console.log("Entrou");
      let valorParcelo = (Pagamento.total / value).toFixed(2);
      Pagamento.numeroparcela = parseInt(value);

      console.log("Entrou - >", Pagamento);
      console.log("Entrou - > numeroparcela", Pagamento.numeroparcela);
      console.log("Entrou - > valorParcelo", valorParcelo);

      setPagamento((prevPagamento) => {
        if (isNaN(numeroparcela) || numeroparcela < 0) {
          console.log("entrou no if");
          // Se o numeroparcela não for um número válido ou for negativo, mantenha os dados iguais
          return {
            ...prevPagamento,
            [name]: value,
            formapagamento: formaPagamento,
            valorVista: 0,
            numeroparcela: Pagamento ? Pagamento.numeroparcela : "",
            total: Pagamento ? produtos.total : "",
            totalSemDesconto: Pagamento ? produtos.totalSemDesconto : "",
            valorParcela: Pagamento ? valorParcelo : 0,
          };
        }
      });
    } else {
      setPagamento((prevPagamento) => {
        // Se o numeroparcela não for um número válido ou for negativo, mantenha os dados iguais
        return {
          ...prevPagamento,
          [name]: value,
          valorParcela: 0,
        };
      });
    }
  };

  const deletarServicoOrcamento = async (
    idOrcamentoTemServico,
    idOrcamento
  ) => {
    console.log("idSonda", idOrcamento);
    console.log("idOrcamentoTemServico", idOrcamentoTemServico);
    if (window.confirm("Tem certeza que deseja excluir este registro?")) {
      const headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      await api
        .delete(
          "/orcamentotemservico/" + idOrcamento + "/" + idOrcamentoTemServico,
          headers
        )
        .then((response) => {
          console.log("response", response);
          setStatus({
            type: "success",
            mensagem: response.data.mensagem,
          });
          fetchData();
        })
        .catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        });
    }
  };

  const handleDadosBancarios = (e) => {
    console.log("dados bancarios", e);
    setDadosBancariosSelecionado(e.target.value);
    getBanco(e.target.value);
  };

  const formatarDataBrasileira = (data) => {
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
  };

  const handleDatasParcelamento = () => {
    const data = new Date(Pagamento.datapagamentoparcelado);
    const datas = [];
    const novaDataPagamento = new Date(Pagamento.datapagamentoparcelado);
    const novaDataPagamentoFormat = formatarDataBrasileira(novaDataPagamento.toISOString().slice(0, 10));
    const objDataPagamento = {
      datapagamento: novaDataPagamentoFormat,
      valorParcela: Pagamento.valorParcela
    };
    datas.push(objDataPagamento);

    for (let i = 1; i < Pagamento.numeroparcela; i++) {
      console.log("i", i);
      const novaData = new Date(data);
      novaData.setMonth(data.getMonth() + i);
      console.log("data", novaData);
      const dataFormatada = formatarDataBrasileira(novaData.toISOString().slice(0, 10));
      const obj = {
        datapagamento: dataFormatada,
        valorParcela: Pagamento.valorParcela
      }
      datas.push(obj);
    }

    console.log("datas", datas);
    setParcelasDb(datas);
    console.log("datasParceladas", parcelasDb);
  };

  const editarOrcamento = async (e) => {
    e.preventDefault();
    console.log("e", e);
    if (formaPagamento === "avista") {
      Pagamento.dadosbancarios = dadosBancariosSelecionado;
    }
    else if (formaPagamento === "parcelado") {
      Pagamento.dadosbancariosparcelado = dadosBancariosSelecionado;
    };

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var periodo = selectedPeriod;
    var parceiro = parceiroSelecionado;

    const orcamento = {
      id,
      cliente,
      vendedor,
      aoscuidadosde,
      validade,
      status,
      detalhes,
      observacaogeral,
      observacaointerna,
      periodo,
      parceiro
    };

    orcamento.dataAtual = zonedTimeToUtc(`${dataAtual}T00:00:00`, 'America/Sao_Paulo');

    await api
      .put(
        "/orcamento",
        { Pagamento, produtos, listaServicos, orcamento, parcelasDb, temservico },
        headers
      )
      .then((response) => {
        //console.log("response", response);
        setRetorno({
          type: "success",
          mensagem: response.data.mensagem,
        });
      })
      .catch((err) => {
        if (err) {
          setRetorno({
            type: "error",
            mensagem: err.data.mensagem,
          });
        } else {
          setRetorno({
            type: "error",
            mensagem: "Tente novamente mais tarde!",
          });
        }
      });
  };

  const atualizaDataOrcamento = async (e) => {


    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const orcamento = {
      id,
      cliente,
      vendedor,
      dataAtual,
      aoscuidadosde,
      validade,
      status,
      detalhes,
      observacaogeral,
      observacaointerna,
    };

    console.log("dataAtual", dataAtual);

    await api
      .put(
        "/atualizaDataOrcamento",
        { orcamento },
        headers
      )
      .then((response) => {
        //console.log("response", response);
        setRetorno({
          type: "success",
          mensagem: response.data.mensagem,
        });
        window.location.reload();
      })
      .catch((err) => {
        if (err) {
          setRetorno({
            type: "error",
            mensagem: err.data.mensagem,
          });
        } else {
          setRetorno({
            type: "error",
            mensagem: "Tente novamente mais tarde!",
          });
        }
      });
  };

  const fetchData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    try {
      const [orcamentoResponse, areaResponse, bancoResponse] =
        await Promise.all([
          await api.get("/orcamento/" + id, headers),
          //await api.get("/instituicao", headers),
          await api.get("/area", headers),
          await api.get("/banco/" + idEmpresa, headers),
        ]);

      // Tratar a resposta do primeiro endpoint (orcamento)
      if (orcamentoResponse.data) {
        console.log("Dados do orçamento", orcamentoResponse.data);
        setCliente(orcamentoResponse.data.instituicao_id);
        setTipoCliente(orcamentoResponse.data.tipoCliente);
        setVendedor(orcamentoResponse.data.vendedor);
        setDataAtual(orcamentoResponse.data.dataAtual);
        setAoscuidadosde(orcamentoResponse.data.aoscuidadosde);
        setValidade(orcamentoResponse.data.validade);
        setDetalhes(orcamentoResponse.data.detalhes);
        setStatus(orcamentoResponse.data.status);
        setStatusInicial(orcamentoResponse.data.status);
        setObservacaogeral(orcamentoResponse.data.observacaogeral);
        setObservacaointerna(orcamentoResponse.data.observacaointerna);
        setTemservico(orcamentoResponse.data.temservico);
        setListaServicosPDF(orcamentoResponse.data.temservico);
        setParceiroSelecionado(orcamentoResponse.data.vinculo);

        var quantidadeServicosNovos = listaServicos.length;
        if (produtos.desconto != 0) {
          debugger;
          console.log("fora do if diferente de 0", produtos.desconto);
          var descontoAtual = produtos.desconto;


          if (quantidadeServicosNovos === 0) {
            debugger;
            setquantidadeprodutos(orcamentoResponse.data.nprodutos);

            var totalSD = orcamentoResponse.data.totalSemDesconto;
            var valorD = (produtos.desconto / 100) * totalSD;
            var totalP = parseFloat(totalSD) - parseFloat(valorD);

            setdescontoprodutos(descontoAtual);
            setTotalSemDesconto(orcamentoResponse.data.totalSemDesconto);
            setvalortotalprodutos(totalP);

            setTotalSemDesconto(totalSD);
          }
          else if (quantidadeServicosNovos > 0) {

            debugger;
            var quantidadeAntiga = orcamentoResponse.data.nprodutos;
            quantidadeServicosNovos = parseInt(quantidadeServicosNovos) + parseInt(quantidadeAntiga);
            setquantidadeprodutos(quantidadeServicosNovos);
            var totalServicosNovos = 0;

            for (let index = 0; index < listaServicos.length; index++) {
              const element = listaServicos[index];
              totalServicosNovos = parseFloat(totalServicosNovos) + parseFloat(element.subtotal);
            }

            debugger;
            var totalSD = orcamentoResponse.data.totalSemDesconto;
            totalSD = parseFloat(totalSD) + parseFloat(totalServicosNovos);
            var valorD = (produtos.desconto / 100) * totalSD;
            var totalP = parseFloat(totalSD) - parseFloat(valorD);




            setdescontoprodutos(descontoAtual);
            setTotalSemDesconto(orcamentoResponse.data.totalSemDesconto);
            setvalortotalprodutos(totalP);
            setTotalSemDesconto(totalSD);
            produtos.total = totalP;
            produtos.totalSemDesconto = totalSD;
            Pagamento.total = totalP;
            Pagamento.totalSemDesconto = totalSD;
          }

          //setvalortotalprodutos(totalP);



        } else if (produtos.desconto === 0) {
          debugger;

          console.log("fora do if igual de 0", produtos.desconto);
          setdescontoprodutos(orcamentoResponse.data.desconto);


          if (quantidadeServicosNovos === 0) {
            setquantidadeprodutos(orcamentoResponse.data.nprodutos);
            setvalortotalprodutos(orcamentoResponse.data.valortotalfloat);
            setTotalSemDesconto(orcamentoResponse.data.totalSemDesconto);
          }
          else if (quantidadeServicosNovos > 0) {
            var quantidadeAntiga = orcamentoResponse.data.nprodutos;
            quantidadeServicosNovos = parseInt(quantidadeServicosNovos) + parseInt(quantidadeAntiga);
            setquantidadeprodutos(quantidadeServicosNovos);

            var totalServicosNovos = 0;

            for (let index = 0; index < listaServicos.length; index++) {
              const element = listaServicos[index];
              totalServicosNovos = parseFloat(totalServicosNovos) + parseFloat(element.subtotal);
            }

            var totalsd = orcamentoResponse.data.totalSemDesconto;
            totalsd = parseFloat(totalsd) + parseFloat(totalServicosNovos);

            setvalortotalprodutos(totalsd);
            setTotalSemDesconto(totalsd);
          }
        }

        //setvalortotalprodutos(orcamentoResponse.data.valortotalfloat);
        setformapagamento(orcamentoResponse.data.formapagamento);
        setnumeroparcelas(orcamentoResponse.data.nparcelas);
        setparcelas(orcamentoResponse.data.parcelas);

        setDataPagamento(orcamentoResponse.data.datapagamento);
        //setSelectedPeriod(orcamentoResponse.data.periodo);
        setParcelasDb(orcamentoResponse.data.parcelasDb);
        setDataPagamentoParcelado(
          orcamentoResponse.data.datapagamentoparcelado
        );
        if (orcamentoResponse.data.formapagamento === "avista") {
          setDadosBancarios(orcamentoResponse.data.dadosbancarios);
          getBanco(orcamentoResponse.data.dadosbancarios);
        } else if (orcamentoResponse.data.formapagamento === "parcelado") {
          setDadosBancariosParcelado(orcamentoResponse.data.dadosbancariosparcelado);
          getBanco(orcamentoResponse.data.dadosbancariosparcelado);
        }
      } else {
        console.log("Erro: Usuário não encontrado!");
      }

      // Tratar a resposta do segundo endpoint (cliente)
      if (orcamentoResponse.data.tipoCliente != undefined || orcamentoResponse.data.tipoCliente != null) {
        getCliente(orcamentoResponse.data.tipoCliente);
      } else {
        console.log("Erro: instituicao não encontrado!");
      }

      // Tratar a resposta do terceiro endpoint (area)
      if (areaResponse.data) {
        setAreaOptions(areaResponse.data);
      } else {
        console.log("Erro: Area não encontrado!");
      }

      if (bancoResponse.data) {
        setBancoOptions(bancoResponse.data);
      } else {
        console.log("Erro: DADOS BANCARIOS não encontrado!");
      }

      

      console.log("Adicionar Serviços -> useEffect");
      console.log("produtos ->  ", produtos);
      console.log("Pagamento ->  ", Pagamento);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  // useEffect(() => {
  //     const headers = {
  //         'headers': {
  //             'Content-Type': 'application/json',
  //             'Authorization': 'Bearer ' + localStorage.getItem('token')
  //         }
  //     };

  //     const getOrcamento = async () => {

  //         await api.get("/orcamento/" + id, headers)
  //             .then((response) => {
  //                 if (response.data) {
  //                     console.log("get useEffect", response.data);

  //                     setCliente(response.data.instituicao_id);
  //                     setVendedor(response.data.vendedor);
  //                     setDataAtual(response.data.dataAtual);
  //                     setPrazo(response.data.prazo);
  //                     setAoscuidadosde(response.data.aoscuidadosde);
  //                     setValidade(response.data.validade);
  //                     setDetalhes(response.data.detalhes);
  //                     setStatus(response.data.status);
  //                     setObservacaogeral(response.data.observacaogeral);
  //                     setObservacaointerna(response.data.observacaointerna);
  //                     setTemservico(response.data.temservico);
  //                     setquantidadeprodutos(response.data.nprodutos);
  //                     setdescontoprodutos(response.data.desconto);
  //                     setvalortotalprodutos(response.data.valortotalfloat);
  //                     setformapagamento(response.data.formapagamento);
  //                     setnumeroparcelas(response.data.nparcelas);
  //                     setparcelas(response.data.parcelas);
  //                     setTotalSemDesconto(response.data.totalSemDesconto);
  //                 } else {
  //                     setRetorno({
  //                         type: 'redWarning',
  //                         mensagem: "Erro: Usuário não encontrado !"
  //                     });
  //                 }
  //                 //console.log(response.data);

  //             }).catch((err) => {
  //                 if (err.response) {
  //                     setRetorno({
  //                         type: 'redWarning',
  //                         mensagem: err.response.data.mensagem
  //                     });
  //                 } else {
  //                     setRetorno({
  //                         type: 'redWarning',
  //                         mensagem: "Erro: tente mais tarde !"
  //                     });
  //                 }
  //             })
  //     };

  //     const instituicao = async () => {
  //         try {
  //             const response = await api.get("/instituicao", headers);
  //             console.log("response typeUser", response);
  //             setClienteOptions(response.data);
  //         } catch (error) {
  //             console.error('Erro ao obter opções de vinculo2:', error);
  //         }
  //     };

  //     const getArea = async () => {
  //         await api.get("/area", headers)
  //             .then((response) => {
  //                 console.log("area", response)
  //                 setAreaOptions(response.data);
  //             }).catch((err) => {
  //                 setRetorno({
  //                     type: 'error',
  //                     mensagem: 'Erro: Tente mais tarde!'
  //                 });
  //             });
  //     };

  //     getArea();
  //     instituicao();
  //     getOrcamento();
  // }, [id]);

  useEffect(() => {
    //console.log("useEffect Editar Orçamento");
    fetchData();
    getEmpresa();
    getParceiro();
  }, [id]);

  // useEffect(() => {
  //   listaServicos.map((servico) => {
  //     servico.idarea = servico.area_id;
  //     servico.quantidade = servico.quant;
  //   });
  //   setListaServicosPDF([...temservico, ...listaServicos]);
  // }, [listaServicosPDF]);

  const handleDataPagamento = (e) => {
    console.log("e dataPagamento", e);
    Pagamento.datapagamento = e.target.value;
    setPagamento({
      ...Pagamento,
      [e.target.name]: e.target.value,
    });
  };

  const handleDataPagamentoParcelado = (e) => {
    console.log("e dataPagamento", e);
    Pagamento.datapagamentoparcelado = e.target.value;
    setPagamento({
      ...Pagamento,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //     const fetchData = async () => {
  //         const headers = {
  //             'Content-Type': 'application/json',
  //             'Authorization': 'Bearer ' + localStorage.getItem('token')
  //         };

  //         try {
  //             const orcamentoResponse = await api.get("/orcamento/" + id, headers);
  //             if (orcamentoResponse.data) {
  //                 setCliente(orcamentoResponse.data.instituicao_id);
  //                 setVendedor(orcamentoResponse.data.vendedor);
  //                 setDataAtual(orcamentoResponse.data.dataAtual);
  //                 setPrazo(orcamentoResponse.data.prazo);
  //                 setAoscuidadosde(orcamentoResponse.data.aoscuidadosde);
  //                 setValidade(orcamentoResponse.data.validade);
  //                 setDetalhes(orcamentoResponse.data.detalhes);
  //                 setStatus(orcamentoResponse.data.status);
  //                 setObservacaogeral(orcamentoResponse.data.observacaogeral);
  //                 setObservacaointerna(orcamentoResponse.data.observacaointerna);
  //                 setTemservico(orcamentoResponse.data.temservico);
  //                 setquantidadeprodutos(orcamentoResponse.data.nprodutos);
  //                 setdescontoprodutos(orcamentoResponse.data.desconto);
  //                 setvalortotalprodutos(orcamentoResponse.data.valortotalfloat);
  //                 setFormaPagamento(orcamentoResponse.data.formapagamento);
  //                 setnumeroparcelas(orcamentoResponse.data.nparcelas);
  //                 setparcelas(orcamentoResponse.data.parcelas);
  //                 setTotalSemDesconto(orcamentoResponse.data.totalSemDesconto);
  //             } else {
  //                 console.log("Erro: Usuário não encontrado!");
  //             }

  //             const clienteResponse = await api.get("/instituicao", headers);
  //             if (clienteResponse.data) {
  //                 setClienteOptions(clienteResponse.data);
  //             } else {
  //                 console.log("Erro: instituicao não encontrado!");
  //             }

  //             const areaResponse = await api.get("/area", headers);
  //             setAreaOptions(areaResponse.data);

  //             console.log("Adicionar Serviços -> useEffect");
  //             console.log("produtos ->  ", produtos);
  //             console.log("Pagamento ->  ", Pagamento);
  //         } catch (error) {
  //             console.error("Erro:", error);
  //         }
  //     };

  //     fetchData();
  // }, [id]);

  const valoresPermitidos = ['43', '44', '45', '50', '52', '55', '54', '53'];

  const mostrarInput = valoresPermitidos.includes(valorServico);

  const [selectedPeriod, setSelectedPeriod] = useState('Mensal');

  // const handlePeriodChange = (event) => {
  //   console.log(event);
  //   setSelectedPeriod(event.target.value);
  // };

  const [tipoCliente, setTipoCliente] = useState(null);

  const handleTipoClienteChange = e => {
    setTipoCliente(e.target.value);
    console.log("tipoCliente e.target.value", e.target.value);
    console.log("tipoCliente ", tipoCliente);
    getCliente(e.target.value);
  };

  const getCliente = async (tcliente) => {
    try {
      const response = await api.post("/instituicaoTipoCliente", { tcliente }, headers);
      console.log("response.data", response.data);
      if (response.data) {
        setClienteOptions(response.data);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro não encontrado");
      }
    }
  };

  const getAreaNameById = (id) => {
    console.log('Buscando nome da área para ID:', id); // Log para depuração
    console.log('Area options:', areaOptions); // Log para depuração
    const area = areaOptions.find((option) => option.id === Number(id));
    console.log('Resultado da busca:', area); // Log para depuração
    return area ? area.area : 'Área Desconhecida';
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleParceiroChange = e => {

    console.log("Parceiro e.target.value", e.target.value);
    setParceiroSelecionado(e.target.value);

  };

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <div className="container" style={{ marginLeft: "220px" }}>
        <br />
        <br />
        <h1>Editar Orçamento</h1>
        <hr />
        <form className="row">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Dados gerais</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label className="form-label">Parceiro:</label>
                      </b>
                      <select
                        name="parceiro"
                        className="form-select"
                        onChange={handleParceiroChange}
                        value={parceiroSelecionado}
                      >
                        <option value="">Selecione o parceiro</option>
                        {parceiros.map((parc) => (
                          <option key={parc.id} value={parc.id}>
                            {parc.nomefantasia}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label className="form-label">Tipo Cliente:</label>
                      </b>
                      <select
                        name="tipoCliente"
                        className="form-select"
                        onChange={handleTipoClienteChange}
                        value={tipoCliente}
                      >
                        <option value="">Selecione o tipo</option>
                        <option value="PJ">Pessoa Juridica</option>
                        <option value="PL">Profissional Liberal</option>
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label for="inputCliente" className="form-label">
                        <b>Cliente:</b>
                      </label>
                      <select
                        id="inputCliente"
                        className="form-select"
                        name="cliente"
                        value={cliente}
                        onChange={handleChange}
                      >
                        <option value="">Selecione</option>
                        {clienteOptions.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label htmlFor="vendedor" className="form-label">
                        Vendedor/Responsável
                      </label>
                      <input
                        type="text"
                        name="vendedor"
                        disabled
                        className="form-control"
                        value={vendedor}
                        onChange={(text) => setVendedor(text.target.value)}
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label htmlFor="data" className="form-label">
                        Data de criação do orçamento
                      </label>
                      <input
                        type="date"
                        name="dataAtual"
                        className="form-control"
                        value={dataAtual}
                        onChange={(e) => handleData(e.target.value)}
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <label htmlFor="aosCuidados" className="form-label">
                        Aos Cuidados de
                      </label>
                      <input
                        type="text"
                        name="aoscuidadosde"
                        className="form-control"
                        value={aoscuidadosde}
                        onChange={(text) => setAoscuidadosde(text.target.value)}
                        onKeyPress={(event) => handleKeyPress(event)}
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <label htmlFor="validade" className="form-label">
                        Validade(dias)
                      </label>
                      <input
                        type="number"
                        name="validade"
                        className="form-control"
                        value={validade}
                        onChange={(text) => setValidade(text.target.value)}
                        onKeyPress={(event) => handleKeyPress(event)}
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="status" className="form-label">
                          Status:
                        </label>
                      </b>
                      <select
                        id="status"
                        className="form-select"
                        name="status"
                        onChange={handleSelectTitulacao}
                        value={status}
                        disabled={statusInicial === 'aprovado'}
                      >
                        <option value="">Selecione</option>
                        <option value="em analise">em analise</option>
                        <option value="aprovado">Aprovado</option>
                        <option value="rejeitado">Rejeitado</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                      <label htmlFor="introducao" className="form-label">
                        Detalhes
                      </label>
                      <textarea
                        type="text"
                        name="detalhes"
                        className="form-control"
                        rows="4"
                        value={detalhes}
                        onChange={(text) => setDetalhes(text.target.value)}
                      ></textarea>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Serviços</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <b>
                        <label for="inputArea" className="form-label">
                          Área*:
                        </label>
                      </b>
                      <select
                        id="inputArea"
                        className="form-select"
                        name="area_id"
                        onChange={handleAreaChange}
                        value={area_id}
                      >
                        <option value="">Selecione</option>
                        {areaOptions.map((opcao) => (
                          <option value={opcao.id}>{opcao.area}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <b>
                          <label className="form-label">Serviço:</label>
                        </b>
                        <select
                          className="form-select"
                          id="servico"
                          name="servico"
                          value={valorServico}
                          onChange={handleServicosSelectedChange}
                        >
                          <option value="">Selecione</option>
                          {servicosDaApi.map((servico) => (
                            <option key={servico.id} value={servico.id}>
                              {servico.servico}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="inputDescricao" className="form-label">
                          Descrição:
                        </label>
                      </b>
                      {mostrarInput ? (
                        <input
                          type="text"
                          autoComplete="off"
                          name="descricao"
                          className="form-control"
                          value={servicoSelecionado.descricao}
                          onChange={handleServicosChange}
                        />
                      ) : (
                        <select
                          id="inputDescricao"
                          className="form-select"
                          name="descricao"
                          value={servicoSelecionado.descricao}
                          onChange={handleServicosChange}
                        >
                          <option value="">Selecione</option>
                          {valorServico === "32" && (
                            <>
                              <option value="Cálculo de Blindagem Sala de Raios X Intraoral">
                                Cálculo de Blindagem Sala de Raios X Intraoral
                              </option>
                              <option value="Cálculo de Blindagem Sala de Raios X Extraoral">
                                Cálculo de Blindagem Sala de Raios X Extraoral
                              </option>
                              <option value="Cálculo de Blindagem Sala de Raios X">
                                Cálculo de Blindagem Sala de Raios X
                              </option>
                              <option value="Cálculo de Blindagem Sala de Mamografia">
                                Cálculo de Blindagem Sala de Mamografia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Hemodinâmica">
                                Cálculo de Blindagem Sala de Hemodinâmica
                              </option>
                              <option value="Cálculo de Blindagem Sala de Tomografia">
                                Cálculo de Blindagem Sala de Tomografia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Densitometria Óssea">
                                Cálculo de Blindagem Sala de Densitometria Óssea
                              </option>
                              <option value="Cálculo de Blindagem Sala de Liptripsia">
                                Cálculo de Blindagem Sala de Liptripsia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Arco Cirúrgico">
                                Cálculo de Blindagem Sala de Arco Cirúrgico
                              </option>
                              <option value="Plano de Proteção Radiológica e Memorial Descritivo">
                                Plano de Proteção Radiológica e Memorial
                                Descritivo
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X">
                                Programa de Garantia da Qualidade - Raios X
                              </option>
                              <option value="Programa de Garantia da Qualidade - Mamografia">
                                Programa de Garantia da Qualidade - Mamografia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Tomografia">
                                Programa de Garantia da Qualidade - Tomografia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X Fluoroscopia">
                                Programa de Garantia da Qualidade - Raios X
                                Fluoroscopia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X Intraoral">
                                Programa de Garantia da Qualidade - Raios X
                                Intraoral
                              </option>
                              <option value="Memorial Descritivo">
                                Memorial Descritivo
                              </option>
                              <option value="Programa de Garantia da Qualidade - Ressonância Magnética Nuclear">
                                Programa de Garantia da Qualidade - Ressonância
                                Magnética Nuclear
                              </option>
                              <option value="Programa de Garantia da Qualidade - Ultrassom">
                                Programa de Garantia da Qualidade - Ultrassom
                              </option>
                              <option value="Programa de Educação Continuada">
                                Programa de Educação Continuada
                              </option>
                            </>
                          )}
                          {valorServico === "35" && (
                            <>
                              <option value="Raios X Intraoral - bienal">
                                Raios X Intraoral - bienal
                              </option>
                              <option value="Raios X Extraoral - anual">
                                Raios X Extraoral - anual
                              </option>
                              <option value="Raios X - mensal">
                                Raios X - mensal
                              </option>
                              <option value="Raios X - semestral">
                                Raios X - semestral
                              </option>
                              <option value="Raios X - anual">
                                Raios X - anual
                              </option>
                              <option value="Mamografia - diario">
                                Mamografia - diario
                              </option>
                              <option value="Mamografia - mensal">
                                Mamografia - mensal
                              </option>
                              <option value="Mamografia - semestral">
                                Mamografia - semestral
                              </option>
                              <option value="Mamografia - anual">
                                Mamografia - anual
                              </option>
                              <option value="Tomografia - mensal">
                                Tomografia - mensal
                              </option>
                              <option value="Tomografia - semanal">
                                Tomografia - semanal
                              </option>
                              <option value="Tomografia - anual">
                                Tomografia - anual
                              </option>
                              <option value="Raios X Fluoroscopia - semanal">
                                Raios X Fluoroscopia - semanal
                              </option>
                              <option value="Raios X Fluoroscopia - mensal">
                                Raios X Fluoroscopia - mensal
                              </option>
                              <option value="Raios X Fluoroscopia - semestral">
                                Raios X Fluoroscopia - semestral
                              </option>
                              <option value="Raios X Fluoroscopia - anual">
                                Raios X Fluoroscopia - anual
                              </option>
                              <option value="Raios X Telecomandado - semanal">
                                Raios X Telecomandado - semanal
                              </option>
                              <option value="Raios X Telecomandado - semestral">
                                Raios X Telecomandado - semestral
                              </option>
                              <option value="Raios X Telecomandado - anual">
                                Raios X Telecomandado - anual
                              </option>
                              <option value="EPI - semestral">
                                EPI - semestral
                              </option>
                              <option value="EPI - anual">EPI - anual</option>
                              <option value="EPI - bienal">EPI - bienal</option>
                              <option value="Negatoscópio e/ou Monitor de Laudos - anual">
                                Negatoscópio e/ou Monitor de Laudos - anual
                              </option>
                              <option value="Sistema de Imagem Filme - Raios X - anual">
                                Sistema de Imagem Filme - Raios X - anual
                              </option>
                              <option value="Sistema de Imagem Filme - Mamografia - anual">
                                Sistema de Imagem Filme - Mamografia - anual
                              </option>
                              <option value="Sistema de Imagem CR - Raios X - anual">
                                Sistema de Imagem CR - Raios X - anual
                              </option>
                              <option value="Sistema de Imagem CR - Mamografia - anual">
                                Sistema de Imagem CR - Mamografia - anual
                              </option>
                              <option value="Densitometria óssea - mensal">
                                Densitometria óssea - mensal
                              </option>
                              <option value="Densitometria óssea - semestral">
                                Densitometria óssea - semestral
                              </option>
                              <option value="Densitometria óssea - anual">
                                Densitometria óssea - anual
                              </option>
                              <option value="Ultrassom - anual">
                                Ultrassom - anual
                              </option>
                              <option value="Ressonancia Magnética Nuclear - semanal">
                                Ressonancia Magnética Nuclear - semanal
                              </option>
                              <option value="Ressonancia Magnética Nuclear - anual">
                                Ressonancia Magnética Nuclear - anual
                              </option>
                            </>
                          )}
                          {valorServico === "33" && (
                            <>
                              <option value="Raios X Intraoral - Levantamento Radiométrico - quadrienal">
                                Raios X Intraoral - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X Intraoral - Radiação de Fuga do cabeçote -
                                quadrienal
                              </option>
                              <option value="Raios X Intraoral - Radiação Espalhada - quadrienal">
                                Raios X Intraoral - Radiação Espalhada -
                                quadrienal
                              </option>
                              <option value="Raios X Extraoral - Levantamento Radiométrico - quadrienal">
                                Raios X Extraoral - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X - Levantamento Radiométrico - quadrienal">
                                Raios X - Levantamento Radiométrico - quadrienal
                              </option>
                              <option value="Raios X - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X - Radiação de Fuga do cabeçote -
                                quadrienal
                              </option>
                              <option value="Raios X - Radiação Espalhada - quadrienal">
                                Raios X - Radiação Espalhada - quadrienal
                              </option>
                              <option value="Mamografia - Levantamento Radiométrico - quadrienal">
                                Mamografia - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Mamografia - Radiação de Fuga do cabeçote - quadrienal">
                                Mamografia - Radiação de Fuga do cabeçote -
                                quadrienal
                              </option>
                              <option value="Tomografia - Levantamento Radiométrico - quadrienal">
                                Tomografia - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal">
                                Raios X Fluoroscopia - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X Fluoroscopia - Radiação de Fuga do
                                cabeçote - quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Radiação Espalhada - quadrienal">
                                Raios X Fluoroscopia - Radiação Espalhada -
                                quadrienal
                              </option>
                            </>
                          )}
                          {valorServico === "48" && (
                            <>
                              <option value="EAD">
                                EAD
                              </option>
                              <option value="Presencial">
                                Presencial
                              </option>
                            </>
                          )}
                          {valorServico === '51' && <>
                            <option value="Levantamento radiométrico / Wipe test">Levantamento radiométrico / Wipe test</option>
                          </>}
                          {valorServico === '46' && <>
                            <option value="SPR TITULAR">SPR TITULAR</option>
                            <option value="SPR SUBSTITUTO">SPR SUBSTITUTO</option>
                          </>}
                          {valorServico === '56' && <>
                            <option value="Plano de Proteção Radiológico">Plano de Proteção Radiológico</option>
                            <option value="Cálculo de Blindagem Instalação V">Cálculo de Blindagem Instalação V</option>
                            <option value="Cálculo de Blindagem Instalação VI">Cálculo de Blindagem Instalação VI</option>
                          </>}
                          {valorServico === '57' && <>
                            <option value="Medidor de Atividade - diário">Medidor de Atividade - diário</option>
                            <option value="Medidor de Atividade - mensal">Medidor de Atividade - mensal</option>
                            <option value="Medidor de Atividade - semestral">Medidor de Atividade - semestral</option>
                            <option value="Medidor de Atividade - anual">Medidor de Atividade - anual</option>
                            <option value="Aceitação Medidor de Atividade">Aceitação Medidor de Atividade</option>
                            <option value="Gama Câmara - diário">Gama Câmara - diário</option>
                            <option value="Gama Câmara - mensal">Gama Câmara - mensal</option>
                            <option value="Gama Câmara - semestral">Gama Câmara - semestral</option>
                            <option value="Gama Câmara - anual">Gama Câmara - anual</option>
                            <option value="Aceitação Gama Câmara">Aceitação Gama Câmara</option>
                            <option value="PETCT - diário">PETCT - diário</option>
                            <option value="PETCT - trimestral">PETCT - trimestral</option>
                            <option value="PETCT - anual">PETCT - anual</option>
                            <option value="Aceitação PETCT">Aceitação PETCT</option>
                            <option value="Geiger Muller - mensal">Geiger Muller - mensal</option>
                            <option value="Aceitação Geiger Muller">Aceitação Geiger Muller</option>
                            <option value="Gama Probe - semestral">Gama Probe - semestral</option>
                            <option value="Aceitação Gama Probe">Aceitação Gama Probe</option>
                          </>}
                          {valorServico === '58' && <>
                            <option value="Supervisão em Proteção Radiológica (SPR Titular)">Supervisão em Proteção Radiológica (SPR Titular)</option>
                            <option value="Gestão de Medicina nuclear">Gestão de Medicina nuclear</option>
                            <option value="Licenciamento de Serviço de Medicina Nuclear Junto a CNEN">Licenciamento de Serviço de Medicina Nuclear Junto a CNEN</option>
                          </>}
                        </select>
                      )}
                    </div>
                    {treinamentoOptions && treinamentoOptions.length > 0 && (
                      <div className="col-md-4">
                        <b>
                          <label for="inputArea" className="form-label">
                            Selecione o Treinamento:
                          </label>
                        </b>
                        <select
                          id="inputArea"
                          className="form-select"
                          name="treinamentoPorArea"
                          onChange={handleChangeTreinamento}
                          value={selectedValueTreinamentos}
                        >
                          <option value="">Selecione</option>
                          {treinamentoOptions.map((opcao) => (
                            <option value={opcao.id}>{opcao.nome}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="col-md-2">
                      <div className="mb-2">
                        <b>
                          <label htmlFor="quant" className="form-label">
                            Quantidade
                          </label>
                        </b>
                        <input
                          type="number"
                          className="form-control"
                          id="quant"
                          name="quant"
                          value={servicos.quant}
                          onChange={handleServicosChange}
                          onKeyPress={(event) => handleKeyPress(event)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-2">
                        <b>
                          <label htmlFor="valor" className="form-label">
                            Valor
                          </label>
                        </b>
                        <div className="input-group mb-3">
                          <span className="input-group-text">R$</span>
                          <input
                            type="number"
                            className="form-control"
                            id="valor"
                            name="valor"
                            value={servicoSelecionado.valor}
                            onChange={handleServicosChange}
                            onKeyPress={(event) => handleKeyPress(event)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                      <b>
                        <label htmlFor="subtotal" className="form-label">
                          Subtotal
                        </label>
                      </b>
                      <div className="input-group mb-3">
                        <span className="input-group-text">R$</span>
                        <input
                          type="text"
                          className="form-control"
                          id="subtotal"
                          name="subtotal"
                          value={servicos.subtotal}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                      <b>
                        <label className="form-label">
                          Modelo de Contratação:
                        </label>
                      </b>
                      <select
                        className={`form-select`}
                        name="periodicidade"
                        onChange={handlePeriodicidadeChange}
                        value={selectedValue}
                      >
                        <option value="">Selecione</option>
                        <option value="Avulso">Avulso</option>
                        <option value="Contrato">Contrato</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row">
                      <label htmlFor="vendedor" className="form-label">
                        <b>
                          Selecione um Período:
                        </b>
                      </label>
                      <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Anual"
                          checked={selectedPeriod === 'Anual'}
                          onChange={handlePeriodChange}
                          disabled={selectedPeriod === "Avulso"}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          Anual
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Semestral"
                          checked={selectedPeriod === 'Semestral'}
                          onChange={handlePeriodChange}
                          disabled={selectedPeriod === "Avulso"}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                          Semestral
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="Trimestral"
                          checked={selectedPeriod === 'Trimestral'}
                          onChange={handlePeriodChange}
                          disabled={selectedPeriod === "Avulso"}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                          Trimestral
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          value="Mensal"
                          checked={selectedPeriod === 'Mensal'}
                          onChange={handlePeriodChange}
                          disabled={selectedPeriod === "Avulso"}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                          Mensal
                        </label>
                      </div>
                      {/* <div>
                          <p>Período selecionado: {selectedPeriod}</p>
                        </div> */}
                    </div>
                  </div>
                  <button
                    onClick={handleAdicionarServico}
                    className="btn btn-primary"
                    type="button"
                  >
                    Adicionar Serviço
                  </button>
                  {listaServicos && listaServicos.length > 0 && (
                    <ul>
                      {listaServicos.map((servico, index) => (
                        <li key={index}>
                          Área: {getAreaNameById(servico.idarea)} - {" "}
                          Serviço: {servico.servico} - Descrição:{" "}
                          {servico.descricao} - {servico.treinamento ? `Treinamento: ${servico.treinamento.nome} - ` : ''} Quantidade: {servico.quant} -
                          Valor: R$ {servico.valor} - Desconto:{" "}
                          {servico.desconto} - Subtotal: R$ {servico.subtotal} -
                          Modelo: {servico.modelo} - Período: {servico.periodo}
                          <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => handleExcluirServico(index)}
                            type="button"
                          >
                            Excluir
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Serviços Adicionados</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {temservico && temservico.length > 0 ? (
                      <>
                        {temservico?.map((item) => (
                          <div className="col">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  Area: {item.area}
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                  {" "}
                                  Serviço: {item.servico}
                                </h6>
                                <p className="card-text">
                                  Descrição: {item.descricao}
                                </p>
                                <p className="card-text">
                                  quantidade: {item.quantidade}
                                </p>
                                <p className="card-text">valor: R$ {item.valor}</p>
                                <p className="card-text">
                                  subtotal: R$ {item.subtotal}
                                </p>
                                <p className="card-text">
                                  Modelo de Contratação:{" "}
                                  {item.modelocontratacao}
                                </p>
                                <p className="card-text">
                                  Período:{" "}
                                  {item.periodo}
                                </p>
                                {item.nomeTreinamento &&
                                  < p className="card-text">
                                    Treinamento:{" "}
                                    {item.nomeTreinamento}
                                  </p>
                                }
                              </div>
                              <div className="col-12 mb-4 text-center">
                                <Link
                                  to={
                                    "/editarServicoOrcamento/" +
                                    item.id +
                                    "/" +
                                    id
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                  >
                                    Editar
                                  </button>
                                </Link>
                                <Link to={"#"}>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      deletarServicoOrcamento(item.id, id)
                                    }
                                    className="btn btn-danger"
                                  >
                                    Excluir
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>Nenhum serviço adicionado.</p> // Mensagem alternativa
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Total</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="produtos" className="form-label">
                          Produtos
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="quantidadeProdutos"
                          name="quantidadeProdutos"
                          value={produtos.quantidade}
                          readOnly
                        />
                        {/* <input type="number" name="produtos" className="form-control" value={produtos} onChange={text => setProdutos(text.target.value)} disabled ></input> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          htmlFor="descontoProdutos"
                          className="form-label"
                        >
                          Desconto (%)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="desconto"
                          name="desconto"
                          value={produtos.desconto}
                          onChange={handleDescontoChange}
                          onKeyPress={(event) => handleKeyPress(event)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="total" className="form-label">
                          Total
                        </label>
                        <input
                          type="text"
                          name="total"
                          className="form-control"
                          value={formatarMoeda(produtos.total)}
                          onChange={(event) =>
                            setValorTotal(event.target.value)
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="total" className="form-label">
                          Total sem desconto
                        </label>
                        <input
                          type="text"
                          name="total"
                          className="form-control"
                          value={formatarMoeda(produtos.totalSemDesconto)}
                          onChange={(event) =>
                            setValorTotal(event.target.value)
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Card de Pagamento */}
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Pagamento</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="pagamentoAVista"
                            name="formaPagamento"
                            value="avista"
                            checked={formaPagamento === "avista"}
                            onChange={handleFormaPagamentoChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pagamentoAVista"
                          >
                            À Vista
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="pagamentoParcelado"
                            name="formaPagamento"
                            value="parcelado"
                            checked={formaPagamento === "parcelado"}
                            onChange={handleFormaPagamentoChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pagamentoParcelado"
                          >
                            Parcelado
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" &&
                            produtos.total !== 0 && (
                              <>
                                <label>
                                  Valor Total a ser Pago a vista é R$
                                  {Pagamento.total}
                                </label>
                              </>
                            )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" &&
                            produtos.total !== 0 && (
                              <>
                                <label>Data de Pagamento</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="datapagamento"
                                  name="datapagamento"
                                  value={Pagamento.datapagamento}
                                  onChange={handleDataPagamento}
                                  required
                                />
                              </>
                            )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" &&
                            produtos.total !== 0 && (
                              <>
                                {bancoOptions && bancoOptions.length > 0 && (
                                  <div>
                                    <label
                                      htmlFor="dadosbancarios"
                                      className="form-label"
                                    >
                                      Dados Bancarios
                                    </label>
                                    <select
                                      className="form-select"
                                      id="dadosbancarios"
                                      name="dadosbancarios"
                                      value={dadosBancariosSelecionado}
                                      onChange={handleDadosBancarios}
                                      required
                                    >
                                      <option value="">Selecione</option>
                                      {bancoOptions.map((banco) => (
                                        <option key={banco.id} value={banco.id}>
                                          {banco.banco}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-6">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "parcelado" &&
                            produtos.total !== 0 && (
                              <>
                                <div className="mb-6">
                                  <b>
                                    <label
                                      htmlFor="numeroparcela"
                                      className="form-label"
                                    >
                                      Digite o numero de parcelas
                                    </label>
                                  </b>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="numeroparcela"
                                    name="numeroparcela"
                                    value={Pagamento.numeroparcela}
                                    onChange={handlePagamentoParcela}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    required
                                  />
                                </div>
                                <div className="md-6">
                                  <b>
                                    <label
                                      htmlFor="totalProdutos"
                                      className="form-label"
                                    >
                                      Total a pagar em cada parcela é:{" "}
                                    </label>
                                  </b>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="totalProdutos"
                                    name="totalProdutos"
                                    value={`R$${Pagamento.valorParcela}`}
                                    onChange={handlePagamentoParcela}
                                    readOnly
                                  />
                                </div>
                                {bancoOptions && bancoOptions.length > 0 && (
                                  <div>
                                    <label
                                      htmlFor="dadosbancarios"
                                      className="form-label"
                                    >
                                      Dados Bancarios
                                    </label>
                                    <select
                                      className="form-select"
                                      id="dadosbancariosparcelado"
                                      name="dadosbancariosparcelado"
                                      value={dadosBancariosSelecionado}
                                      onChange={handleDadosBancarios}
                                      required
                                    >
                                      <option value="">Selecione</option>
                                      {bancoOptions.map((banco) => (
                                        <option key={banco.id} value={banco.id}>
                                          {banco.banco}
                                        </option>
                                      ))}
                                    </select>
                                    <div className="mb-6">
                                      <label>Data de Pagamento</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        id="datapagamentoparcelado"
                                        name="datapagamentoparcelado"
                                        value={Pagamento.datapagamentoparcelado}
                                        onChange={handleDataPagamentoParcelado}
                                        required
                                      />
                                    </div>
                                    <br></br>
                                    <button
                                      onClick={handleDatasParcelamento}
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      Gerar novas parcelas
                                    </button>
                                    {parcelasDb != undefined &&
                                      <ul>
                                        {parcelasDb.map((data, index) => (
                                          <li key={index}>
                                            {data.datapagamento +
                                              " - R$" +
                                              data.valorParcela}
                                          </li>
                                        ))}
                                      </ul>
                                    }
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Card de Observações Internas */}
              <div className="card mb-3">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Observações Internas</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="observacoesInternas" className="form-label">
                      Observações Internas
                    </label>
                    <textarea
                      type="text"
                      name="observacaointerna"
                      className="form-control"
                      value={observacaointerna}
                      onChange={(text) =>
                        setObservacaointerna(text.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4 text-center">
              <Link to={"/orcamento"}>
                <button type="button" className="btn btn-danger me-md-2">
                  Cancelar
                </button>
              </Link>
              {retorno.type === "success" ? (
                <Redirect
                  to={{
                    pathname: "/orcamento",
                    state: {
                      type: "success",
                      mensagem: retorno.mensagem,
                    },
                  }}
                />
              ) : (
                ""
              )}
              <button onClick={editarOrcamento} disabled={statusInicial === 'aprovado'} className="btn btn-success me-md-2">
                Salvar
              </button>
              <button onClick={atualizaDataOrcamento} disabled={statusInicial === 'aprovado'} className="btn btn-success me-md-2">
                Atualizar Data
              </button>
              <button
                type="button"
                onClick={handleGeneratePDF}
                className="btn btn-success"
              >
                Visualizar PDF
              </button>
            </div>
          </div>
        </form>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Erro de Validação"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Erro de Validação</h5>
              <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>{modalMessage}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(false)}>
                Fechar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
