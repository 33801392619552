import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Banco = (props) => {

    const [idEmpresa] = useState(props.match.params.idempresa);
    console.log("id Empresa", idEmpresa);

    const { state } = useLocation();
    console.log("state", state);

    const [data, setData] = useState([]);
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const getContas = async () => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }

        await api.get("/banco/" + idEmpresa, headers)
            .then((response) => {
                if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                    $(dataTableRef.current).DataTable().destroy();
                }
                //console.log("banco", response)
                setData(response.data);
                if (dataTableRef.current) {
                    $(dataTableRef.current).DataTable({
                        retrieve: true,
                        "order": [[0, "desc"]]
                    });
                }
            }).catch((err) => {
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente mais tarde!'
                });
            });

        if (dataTableRef.current) {
            if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable().destroy();
            }
            $(dataTableRef.current).DataTable({
                "order": [[0, "desc"]]
            });
        }
    };


    const deletarBanco = async (idBanco) => {
        if (window.confirm('Tem certeza que deseja excluir este registro?')) {
          const headers = {
            'headers': {
              'Authorization': "Bearer " + localStorage.getItem('token')
            }
          };
    
          try {
            const response = await api.delete("/banco/" + idBanco, headers);
            //console.log("response excluir", response);
            setRetorno({
              type: 'success',
              mensagem: response.data.mensagem
            });
            getContas();
          } catch (err) {
            if (err.response) {
              console.log("response", err.response);
            } else {
              console.log("'Erro: Tente mais tarde!");
            }
          }
        }
      };


    useEffect(() => {
        getContas();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Bancos</h1>
                    {status.type === 'success' ?
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>{status.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <Link to={"/empresa"}>
                        <button type="button" className="btn btn-success me-md-2">
                            Voltar
                        </button>
                    </Link>

                    <Link to={"/addbanco/" + idEmpresa}>
                        <button type="button" className="btn btn-success me-md-2">
                            Adicionar
                        </button>
                    </Link>

                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">banco</th>
                                            <th scope="col">agencia</th>
                                            <th scope="col">conta</th>
                                            <th scope="col">tipoconta</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(constas => (
                                            <tr key={constas.id}>
                                                <th scope="row">{constas.id}</th>
                                                <td>{constas.banco}</td>
                                                <td>{constas.agencia}</td>
                                                <td>{constas.conta}</td>
                                                <td>{constas.tipoconta}</td>
                                                <td>
                                                    <div className="d-flex flex-row mb-3">
                                                        <div>
                                                            <Link to={"/viewbanco/" + idEmpresa + "/" + constas.id}>
                                                                <button type="button" className="btn btn-warning me-md-2">
                                                                    <i className="fa-solid fa-eye"></i>Visualizar
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Link to={"/editarbanco/" + idEmpresa + "/" + constas.id}>
                                                                <button type="button" className="btn btn-success me-md-2">
                                                                    <i className="fa fa-edit"></i>Editar
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Link to={"#"}>
                                                                <button type="button" onClick={() => deletarBanco(constas.id)} className="btn btn-danger">
                                                                    <i className="fa-solid fa-trash"></i>
                                                                    Deletar
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        </div>
    );
}
