import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import axios from 'axios';

import localforage from 'localforage';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import certificado_vazio from '../../../assets/img/conteudoProgramaticoVazio.png';
import Assinatura from '../../../assets/img/AssinaturaBruno.png';
import AssinaturaRoberta from '../../../assets/img/assinatura_roberta.png';

import CertificadoNovoVazio from '../../../assets/img/CertificadoNovoVazio.png'

import ConteudoProgramatico from '../../../assets/img/ConteudoProgramaticoSemAssinatura.png'
import CertificadoFundoV2 from '../../../assets/img/CertificadoSemLogo.png'

import logoReduto from '../../../assets/img/assinaturaEmail.png'

import { ptBR } from 'date-fns/locale';

import Modal from 'react-modal';

Modal.setAppElement('#root');

export const ViewOs = (props) => {

    const idEmpresa = localStorage.getItem("empresa");

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState('');
    const [tipo, setTipo] = useState('');
    const [envioTreinamento, setEnvioTreinamento] = useState('');
    const [envioCertificado, setEnvioCertificado] = useState('');
    const [envioServico, setEnvioServico] = useState('');
    const [forma, setForma] = useState('');
    const [dataTreinamento, setDataTreinamento] = useState('');

    const [clienteOptions, setClienteOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);

    const [dataVisita, setDataVisita] = useState('');
    const [prazo, setPrazo] = useState('');
    const [status, setStatus] = useState('');

    const [telaAtual, setTelaAtual] = useState('viewos');
    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);
    const [arquivonf, setArquivoNf] = useState('');
    const [nf, setNf] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [viewMostrar, setViewMostrar] = useState(null);
    const [statusCliente, setStatusCliente] = useState('');

    const [infoColaboradores, setInfoColaboradores] = useState([]);
    const [conteudoProgramatico, setConteudoProgramatico] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const [email, setEmail] = useState('');


    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    const handleChange = (e) => {
        e.preventDefault();
        setCliente(e.target.value);
    };

    const handleData = (date) => {
        console.log("date", date);
        setDatacriacao(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const formaSelectChange = (event) => {
        setForma(event.target.value);
    }

    const handleDataVisita = (date) => {

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazo(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        setPrazo(date);
    };

    const handleSelectChange = (event) => {
        console.log("event.target.value", event.target.value);
        setStatus(event.target.value);
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setNf(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (nf !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', nf);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const VisualizarPDFTodos = async () => {
        const pdfUrls = [nf, ...servicos.map(servico => servico.endImagem)];
        console.log("pdfUrls", pdfUrls);
        if (pdfUrls !== null && pdfUrls.length > 0) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', pdfUrls);

                // Abra a nova aba
                const novaAba = window.open('/viewvariospdfs/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                console.log("if que verifica arquivo", e);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(e);
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };

    const verificaPdfVisualizar = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                try {
                    // Armazene os dados no localforage
                    await localforage.setItem('pdfData', e);

                    // Abra a nova aba
                    const novaAba = window.open('/viewpdf/', '_blank');
                    novaAba.focus();
                } catch (error) {
                    console.error('Erro ao armazenar os dados no localforage:', error);
                }
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const VisualizarCertificado = async (e) => {

        console.log("conteudoProgramatico", conteudoProgramatico);
        console.log("infoColaboradores", infoColaboradores);


        const pageWidth = 871;
        const pageHeight = 624;

        try {
            // Converte as imagens necessárias para base64
            //const backgroundImage = await getBase64ImageFromURL(certificado_vazio);
            const AssinaturaConvertida = await getBase64ImageFromURL(Assinatura);
            const AssinaturaConvertidaRoberta = await getBase64ImageFromURL(AssinaturaRoberta);
            const CertificadoFundo = await getBase64ImageFromURL(CertificadoFundoV2);
            const ConteudoProgramaticoFundo = await getBase64ImageFromURL(ConteudoProgramatico);
            const logo = await getBase64ImageFromURL(logoReduto);
            //const conteudoProgramaticoImagem = await getBase64ImageFromURL(conteudoProgramatico.imagemConteudoProgramatico); // Converte a imagem do conteúdo programático

            const dataFormatada = format(new Date(dataTreinamento), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });

            // Inicialize o array de páginas
            let pages = [];

            var nomeCliente = clienteOptions.find(x => x.id === cliente);

            console.log("nomeCliente", nomeCliente);

            const dataPorExtenso = formatarDataPorExtenso("Cidade Exemplo");

            pages.push(
                {
                    image: CertificadoFundo,
                    width: pageWidth,
                    height: pageHeight,
                    absolutePosition: { x: 0, y: 0 }
                },
                {
                    image: logo,
                    width: 180,
                    absolutePosition: { x: 650, y: 5 },
                    margin: [0, 0, 0, 0]
                },
                {
                    text: `Reconhecemos que a Instituição:`,
                    alignment: "center",
                    width: pageWidth,
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    absolutePosition: { x: 280, y: 180 } // Primeira linha de texto
                },
                {
                    text: `${nomeCliente.nome}, CNPJ: ${nomeCliente.cnpj}`,
                    alignment: "center",
                    width: pageWidth,
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    absolutePosition: { x: 250, y: 200 } // Segunda linha de texto, ajustada para ficar logo abaixo
                },
                {
                    text: `Está em conformidade com os requisitos estabelecidos pela RDC 611, de 9 de março de 2022, Art.15  - O serviço de radiologia diagnóstica ou intervencionista deve implementar Programa de Educação Permanente para toda a equipe, em conformidade com o disposto nesta Resolução e nas demais normativas aplicáveis.`,
                    alignment: "center",
                    width: pageWidth - 200, // Define uma largura limitada para o texto
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    margin: [200, 220, 0, 0]
                    //vERSAO Bruno
                    // alignment: "right",
                    // width: pageWidth - 180, // Define uma largura limitada para o texto
                    // bold: true,
                    // fontSize: 20,
                    // color: "#477f7f",
                    // margin: [200, 220, 0, 0]
                },
                {
                    text: `${nomeCliente.cidadeestado}` + ', ' + dataFormatada, // Exibe a data abaixo do texto "Está em conformidade..."
                    alignment: "center",
                    width: pageWidth,
                    fontSize: 14,
                    color: "#477f7f",
                    absolutePosition: { x: 220, y: 450 } // Ajuste y conforme necessário para ficar logo abaixo do último texto
                },
                {
                    image: AssinaturaConvertida,
                    width: 180,
                    absolutePosition: { x: 280, y: 490 },
                    margin: [0, 0, 0, 0]
                },
                {
                    image: AssinaturaConvertidaRoberta,
                    width: 180,
                    absolutePosition: { x: 560, y: 520 },
                    margin: [0, 0, 0, 0]
                },
                {
                    text: '',
                    pageBreak: 'after'
                }
            );


            // Adiciona páginas para cada colaborador
            pages = pages.concat(
                infoColaboradores.map((colaborador, index) => {
                    return [
                        {
                            image: CertificadoFundo, // Imagem convertida para base64
                            width: pageWidth,
                            height: pageHeight,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        {
                            image: logo,
                            width: 180,
                            absolutePosition: { x: 650, y: 5 },
                            margin: [0, 0, 0, 0]
                        },
                        {
                            text: `Reconhecemos que:`,
                            alignment: "center",
                            width: pageWidth,
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            absolutePosition: { x: 280, y: 180 } // Primeira linha de texto
                        },
                        {
                            text: `${colaborador.nomefuncionario}, CPF: ${colaborador.cpffuncionario}`,
                            alignment: "center",
                            width: pageWidth,
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            absolutePosition: { x: 250, y: 200 } // Segunda linha de texto, ajustada para ficar logo abaixo
                        },
                        {
                            text: `Certificamos que o Individuo Ocupacionalmente Exposto (IOE) acima identificado, que faz parte do corpo técnico do ${nomeCliente.nome} participou do treinamento ${colaborador.nomeTreinamento} realizado de forma ${forma} assíncrona, com carga horária de ${colaborador.duracaoTreinamento} horas`,
                            alignment: "center",
                            width: pageWidth - 200, // Define uma largura limitada para o texto
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            margin: [200, 220, 0, 0]
                            //VERSAO BRUNO
                            // alignment: "right",
                            // width: pageWidth - 180, // Define uma largura limitada para o texto
                            // bold: true,
                            // fontSize: 20,
                            // color: "#477f7f",
                            // margin: [200, 220, 0, 0]
                        },
                        {
                            text: `${nomeCliente.cidadeestado}` + ', ' + dataFormatada, // Exibe a data abaixo do texto "Está em conformidade..."
                            alignment: "center",
                            width: pageWidth,
                            fontSize: 14,
                            color: "#477f7f",
                            absolutePosition: { x: 220, y: 420 }// Ajuste y conforme necessário para ficar logo abaixo do último texto
                        },
                        {
                            image: AssinaturaConvertida,
                            width: 180, // Largura da imagem ajustável
                            //alignment: "center", // Centraliza a imagem
                            absolutePosition: { x: 280, y: 490 },
                            margin: [0, 0, 0, 0] // Espaçamento acima da imagem
                        },
                        {
                            image: AssinaturaConvertidaRoberta,
                            width: 180, // Largura da imagem ajustável
                            //: "right", // Centraliza a imagem
                            absolutePosition: { x: 560, y: 520 },
                            margin: [0, 0, 0, 0] // Espaçamento acima da imagem
                        },
                        index < infoColaboradores.length - 1 ? { text: '', pageBreak: 'after' } : {}
                    ];
                }).flat() // Flateia o array para evitar um array de arrays
            );

            // Adiciona uma página em branco antes da tabela
            pages.push({ text: '', pageBreak: 'after' });

            const marginLeft = 50;
            const marginRight = 50;
            const marginTop = 50;
            const marginBottom = 50;

            const conteudoProgramaticoComColunas = [
                {
                    // Tabela com cabeçalho e dados
                    table: {
                        widths: ['*', 'auto'], // Define a largura das colunas
                        body: [
                            // Cabeçalho da tabela
                            [
                                { text: 'Disciplina', bold: true, alignment: 'left', noWrap: true },
                                { text: 'Carga Horária (h)', bold: true, alignment: 'right', noWrap: true }
                            ],
                            // Dados dinâmicos da tabela
                            ...conteudoProgramatico.dadosConteudoProgramatico.map(item => ([
                                { text: removeHtmlTags(item.conteudo), alignment: 'left' },
                                { text: `${item.duracao} h`, alignment: 'right' }
                            ])),
                            // Rodapé da tabela
                            [
                                { text: 'Total de Carga Horária', bold: true, alignment: 'left' },
                                { text: `${conteudoProgramatico.dadosTreinamento.duracao} h`, bold: true, alignment: 'right' }
                            ]
                        ]
                    },
                    layout: 'lightHorizontalLines', // Define um layout de linhas horizontais leves
                    margin: [0, 10, 0, 10] // Margens para a tabela
                }
            ];

            // Configuração da página com a imagem de fundo e conteúdo
            const layoutComColunas = [
                {
                    image: ConteudoProgramaticoFundo,
                    width: pageWidth,
                    height: pageHeight,
                    absolutePosition: { x: 0, y: 0 }
                },
                {
                    image: logo,
                    width: 180,
                    absolutePosition: { x: 650, y: 5 },
                    margin: [0, 0, 0, 0]
                },
                {
                    stack: conteudoProgramaticoComColunas,
                    margin: [250, 80, 0, 0], // Margens personalizadas
                    width: pageWidth - marginLeft - marginRight // Largura total da tabela
                }
            ];


            if (conteudoProgramaticoComColunas.length > 0) {
                pages.push(layoutComColunas);
            }

            const dd = {
                pageSize: {
                    width: pageWidth,
                    height: pageHeight
                },
                content: pages.filter((item, index, array) =>
                    !(item.pageBreak === 'after' && index === array.length - 1) // Remove a última quebra de página desnecessária
                )
            };



            pdfMake.createPdf(dd).open();
        } catch (error) {
            console.error("Erro ao carregar a imagem:", error);
        }
    };

    const getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    function formatarDataPorExtenso(cidade) {
        const data = new Date();
        const dia = data.getDate();
        const mes = [
            "janeiro", "fevereiro", "março", "abril", "maio", "junho",
            "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ][data.getMonth()];
        const ano = data.getFullYear();

        return `${cidade}, ${dia} de ${mes} de ${ano}`;
    }

    function removeHtmlTags(html) {
        // Remove as tags HTML
        let text = html.replace(/<[^>]*>?/gm, '');

        // Substitui entidades HTML como &nbsp; por seus valores correspondentes
        text = text.replace(/&nbsp;/g, ' '); // Substitui &nbsp; por espaço normal
        text = text.replace(/&amp;/g, '&');  // Substitui &amp; por &
        text = text.replace(/&quot;/g, '"'); // Substitui &quot; por aspas duplas
        text = text.replace(/&apos;/g, "'"); // Substitui &apos; por aspas simples
        text = text.replace(/&lt;/g, '<');   // Substitui &lt; por <
        text = text.replace(/&gt;/g, '>');   // Substitui &gt; por >

        // Adiciona outras entidades HTML que você possa precisar remover/substituir

        return text;
    }



    useEffect(() => {

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getOs = async () => {


            await api.get("/agendaos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data agendaos", response);
                        setCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setTipo(response.data.tipo);
                        if (response.data.tipo === 'treinamento') {
                            if (response.data.envioTreinamento === 1) {
                                setEnvioTreinamento("Email Enviado com sucesso!");
                            }
                            if (response.data.envioTreinamento !== 1) {
                                setEnvioTreinamento("Email Não Enviado!");
                            }
                            if (response.data.envioCertificado === 1) {
                                setEnvioCertificado("Certificado Enviado com sucesso!");
                            }
                            if (response.data.envioCertificado !== 1) {
                                setEnvioCertificado("Certificado Não Enviado!");
                            }
                        }
                        if (response.data.tipo === 'servico') {
                            if (response.data.envioServico === 1) {
                                setEnvioServico("Email Enviado com sucesso!");
                            }
                            if (response.data.envioServico !== 1) {
                                setEnvioServico("Email Não Enviado!");
                            }
                        }

                        setDataVisita(response.data.datavisita);
                        setPrazo(response.data.prazo);
                        setStatus(response.data.statusos);

                        verificaPdf(response.data.nfImagem);

                        setStatusCliente(response.data.statusCliente);
                        setEmail(response.data.emailCliente);

                        setForma(response.data.forma);
                        setDataTreinamento(response.data.dataConclusao);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnicoPorEmpresa", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getColaboradoresVinculados = async () => {
            try {
                const response = await api.post("/os/ColaboradoresVinculados", { id }, headers);
                setInfoColaboradores(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        if (tipo === 'treinamento') {
            const getConteudoProgramatico = async () => {
                try {
                    const response = await api.post("/os/ConteudoProgramatico", { id }, headers);
                    setConteudoProgramatico(response.data);
                } catch (error) {
                    console.error("Erro ao obter opções de vinculo2:", error);
                }
            };

            getConteudoProgramatico();
        }


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getColaboradoresVinculados();

        console.log("conteudoProgramatico", conteudoProgramatico);

    }, [id, tipo]);

    const EnviarEmail = async (e) => {
        e.preventDefault();

        var headersm = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        var idCliente = cliente;

        var dadosCliente = clienteOptions.find(x => x.id === cliente);

        await api.put('/osEnviarEmail', { status, id, nf, arquivonf, servicos, email, tipo, forma, dataTreinamento, dadosCliente, infoColaboradores, conteudoProgramatico }, headersm)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

    };

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Visualizar OS</h1>
                <hr />
                <form className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" name="cliente" value={cliente} onChange={handleChange} disabled>
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                <b>Criador da OS</b>
                                            </label>
                                            <input type="text" name="criadordaos" className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                <b>Tipo da OS:</b>
                                            </label>
                                            <input
                                                type="text"
                                                name="tipo"
                                                className="form-control"
                                                value={tipo === 'servico' ? 'serviço' : tipo}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                <b>Data da criação do Orçamento</b>
                                            </label>
                                            <input type="date" name="datacriacao" className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                <b>Data da visita</b>
                                            </label>
                                            <input type="date" name="dataVisita" className="form-control" value={dataVisita} onChange={(e) => handleDataVisita(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="prazo" className="form-label">
                                                <b>Prazo da Os</b>
                                            </label>
                                            <input type="date" name="prazo" className="form-control" value={prazo} onChange={(e) => handleDataPrazo(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <label for="tecnico" className="form-label">
                                                <b>Técnicos*:</b>
                                            </label>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}
                                                disabled
                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange} disabled>
                                                <option value="">Selecione</option>
                                                <option value="Agendado">Agendado</option>
                                                {tipo === 'servico' && (
                                                    <option value="Aguardando agendamento">Aguardando Agendamento</option>
                                                )}
                                                <option value="Aguardando faturamento">Aguardando Faturamento</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Aguardando Envio">Aguardando Envio</option>
                                                )}
                                                <option value="Cancelado">Cancelado</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Enviado">Enviado</option>
                                                )}
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em correção">Em Correção</option>
                                                <option value="em revisão">Em Revisão</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="emitir certificado">Emitir certificado</option>
                                                )}
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                        </div>
                                        {tipo === 'treinamento' && (
                                            <>
                                                <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                                    <b>
                                                        <label htmlFor="datacriacao" className="form-label">
                                                            Data de conclusão do treinamento
                                                        </label>
                                                    </b>
                                                    <input
                                                        type="date"
                                                        name="dataTreinamento"
                                                        className="form-control"
                                                        value={dataTreinamento}
                                                        disabled
                                                        onChange={(e) => setDataTreinamento(e.target.value)}
                                                    ></input>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                                    <b>
                                                        <label htmlFor="inputForma" className="form-label">
                                                            Forma de realização:
                                                        </label>
                                                    </b>
                                                    <select
                                                        id="inputForma"
                                                        className="form-select"
                                                        name="forma"
                                                        value={forma}
                                                        onChange={formaSelectChange}
                                                        disabled
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Presencial">Presencial</option>
                                                        <option value="Remoto">Remoto</option>
                                                        <option value="EAD">EAD</option>
                                                    </select>
                                                </div>
                                            </>
                                        )}
                                        {tipo === 'treinamento' && (
                                            <>
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                    <label htmlFor="envioTreinamento" className="form-label">
                                                        <b>Email do treinamento Enviado</b>
                                                    </label>
                                                    <input
                                                        id="envioTreinamento"
                                                        type="text"
                                                        name="envioTreinamento"
                                                        className="form-control"
                                                        value={envioTreinamento}
                                                        onChange={(e) => setEnvioTreinamento(e.target.value)}
                                                        disabled
                                                    />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                    <label htmlFor="certificadoTreinamento" className="form-label">
                                                        <b>Certificado do treinamento Enviado</b>
                                                    </label>
                                                    <input
                                                        id="certificadoTreinamento"
                                                        type="text"
                                                        name="certificadoTreinamento"
                                                        className="form-control"
                                                        value={envioCertificado}
                                                        onChange={(e) => setEnvioCertificado(e.target.value)}
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {tipo === 'servico' && (
                                            <>
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                    <label htmlFor="envioTreinamento" className="form-label">
                                                        <b>Email do Serviço Enviado</b>
                                                    </label>
                                                    <input
                                                        id="envioServico"
                                                        type="text"
                                                        name="envioServico"
                                                        className="form-control"
                                                        value={envioServico}
                                                        onChange={(e) => setEnvioServico(e.target.value)}
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>
                                        {tipo !== 'treinamento' && (
                                            <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                                <b>
                                                    <label for="inputStatus" className="form-label">Visualizar Todos PDFs:</label>
                                                </b>
                                                <br />


                                                <button type='button' onClick={() => VisualizarPDFTodos()} className="btn btn-success">
                                                    Visualizar PDFs
                                                </button>

                                            </div>
                                        )}

                                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                            <b>
                                                <label for="inputStatus" className="form-label">Visualizar NF:</label>
                                            </b>
                                            <br />

                                            {viewMostrarpdf === 1 && <>
                                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                                    Visualizar NF
                                                </button>
                                            </>}

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                <b>Detalhes</b>
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Serviços</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {servicos.length > 0 && (
                                            <>
                                                {servicos.map((item) => {
                                                    if (item.idservico !== 44 && item.idservico !== 43 && item.idservico !== 45) {
                                                        const isRed = item.criadoaposorcamento === 1;
                                                        return (
                                                            <div className="col-md-4" key={item.id}>
                                                                <div className="card mb-4">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Área: {item.area}</h5>
                                                                        <h6 className="card-subtitle mb-2 text-muted">Serviço: {item.servico}</h6>
                                                                        <p>Status do serviço: {item.statusServico}</p>
                                                                        <p className="card-text">Descrição: {item.descricao}</p>
                                                                        {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                            <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                                        ) : (
                                                                            tipo === 'servico' && (
                                                                                <div>
                                                                                    {item.idequipamento !== null ? (
                                                                                        <>

                                                                                            <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                                            <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                                            <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                                            <p className="card-text">Modelo: {item.modelo}</p>
                                                                                            <p className="card-text">N° de série: {item.numserie}</p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        {tipo === 'treinamento' && (
                                                                            <div className="col-md-4">

                                                                                <button type="button" className="btn btn-success me-md-2" onClick={VisualizarCertificado}>Visualizar Certificados</button>

                                                                            </div>
                                                                        )}
                                                                        {/* <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 form-outline mb-4">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <button type="button" className="btn btn-primary p-2 me-md-2">Visualizar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <>
                                                                            <hr />


                                                                        </>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />
                            <Link to={"/os"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/os',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}