import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import axios from 'axios';

import localforage from 'localforage';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import certificado_vazio from '../../../assets/img/conteudoProgramaticoVazio.png';
import Assinatura from '../../../assets/img/AssinaturaBruno.png';
import AssinaturaRoberta from '../../../assets/img/assinatura_roberta.png';

import CertificadoNovoVazio from '../../../assets/img/CertificadoNovoVazio.png'

import ConteudoProgramatico from '../../../assets/img/ConteudoProgramaticoSemAssinatura.png'
import CertificadoFundoV2 from '../../../assets/img/CertificadoSemLogo.png'

import logoReduto from '../../../assets/img/assinaturaEmail.png'

import { ptBR } from 'date-fns/locale';

import Modal from 'react-modal';

Modal.setAppElement('#root');

export const FaturamentoOS = (props) => {

    const idEmpresa = localStorage.getItem("empresa");
    const [telaAtual, setTelaAtual] = useState('faturamentoos');

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [tipo, setTipo] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [dataTreinamento, setDataTreinamento] = useState('');
    const [prazoentrega, setPrazoentrega] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState([]);
    const [status, setStatus] = useState('');
    const [forma, setForma] = useState('');

    const [clienteOptions, setClienteOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);


    const [areaId, setAreaId] = useState(1);
    const [servicosDaApi, setServicosDaApi] = useState([]);
    const [servico, setServico] = useState("");
    const [descricao, setDescricao] = useState("");

    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});
    const [equipamentos, setEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [listaServicosOs, setListaServicosOs] = useState([]);

    const [dataVisita, setDataVisita] = useState('');
    const [statusCliente, setStatusCliente] = useState('');


    const [id] = useState(props.match.params.id);

    const [todosComPdfParte2, setTodosComPdfParte2] = useState(false);

    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);

    const [viewMostrar, setViewMostrar] = useState(null);
    const [arquivonf, setArquivoNf] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [nf, setNf] = useState('');
    const [image, setImage] = useState('');

    const [email, setEmail] = useState('');

    const [infoColaboradores, setInfoColaboradores] = useState([]);
    const [conteudoProgramatico, setConteudoProgramatico] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    //console.log("id Visualizar Area", id);

    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setCliente(e.target.value);
    // };

    const handleData = (date) => {
        console.log("date", date);
        setDatacriacao(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const handleAreaChange = (e) => {
        getServicos(e.target.value);
        setAreaId(e.target.value);
    };

    const handleServicoChange = (e) => {
        setServico(e.target.value);
    };

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleEquipamentoChange = (e) => {
        setEquipamento(e.target.value);
        getEquipamentoById(e.target.value);
    };

    const handleClienteChange = (e) => {
        setCliente(e.target.value);
        getEquipamentoInstituicao(e.target.value);
    };

    const handleCliente = (e) => {
        console.log("e handleCliente", e);
        setCliente(e);
        getEquipamentoInstituicao(e);
    };

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const formaSelectChange = (event) => {
        setForma(event.target.value);
    }

    const handleDataVisita = (date) => {
        console.log("date", date);

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazoentrega(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        console.log("date", date);
        setPrazoentrega(date);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const getEquipamentoInstituicao = async (e) => {
        try {
            const response = await api.get("/equipamento/" + e, headers);
            if (response.data) {
                console.log("Equipamentos", response.data);
                setEquipamentos(response.data);
            } else {
                console.log("Erro: Equipamentos não encontrados!");
            }
        } catch (error) {
            console.log("Erro ao buscar equipamentos:", error.message);
        }
    };



    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const getServicos = async (areaid) => {
        try {
            const response = await api.get("/servicoarea/" + areaid, headers);
            if (response.data) {
                setServicosDaApi(response.data);
            } else {
                console.log("Erro: serviço não encontrado !");
            }
        } catch (err) {
            if (err) {
                console.log("Erro", err);
                setServicosDaApi([]);
            } else {
                console.log("Erro não encontrado");
            }
        }
    };

    const getEquipamentoById = async (e) => {
        try {
            const response = await api.get("/equipamento/selecionar/" + e, headers);
            console.log("response equipamento", response.data);
            setEquipamentoSelecionado(response.data);
        } catch (error) {
            console.error("Erro ao obter opções de equipamento:", error);
        }
    };

    const handleAdicionarServico = async (e) => {
        e.preventDefault();

        const novoServicoOs = {
            descricao: descricao,
            id_servico: servico,
            servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
            area_id: areaId,
            area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
            equipamento_id: equipamento ? equipamento : "",
            equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento : "",
            os_id: id
        };

        await api.post('/ostemservico', novoServicoOs, headers)
            .then((response) => {
                // setRetorno({
                //     type: 'success',
                //     mensagem: response.data.mensagem
                // });
                fetchData();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

        console.log("novoServicoOs", novoServicoOs);
    };

    const handleExcluirServico = (index) => {
        // Crie uma cópia da lista de serviços
        const novaListaServicos = [...listaServicosOs];

        // Remova o serviço pelo índice
        const servicoRemovido = novaListaServicos.splice(index, 1)[0];

        // Atualize o estado da lista de serviços
        setListaServicosOs(novaListaServicos);
    };

    const SalvarFinanceiroOs = async (e) => {
        e.preventDefault();

        var status = "Aguardando faturamento";


        await api.put('/osatualizarstatus', { status, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const SalvarCorrecaoOs = async (e) => {
        e.preventDefault();

        console.log("status", status);
        console.log("id", id);
        console.log("nf", nf);
        console.log("arquivonf", arquivonf);
        console.log("tipo", tipo);

        var headersm = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        var dadosCliente = clienteOptions.find(x => x.id === cliente);


        await api.put('/osnf', { status, id, nf, arquivonf, servicos, email, tipo, forma, dataTreinamento, dadosCliente, infoColaboradores, conteudoProgramatico  }, headersm)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

    };

    const InsereNfOs = async (e) => {
        e.preventDefault();

        console.log("status", status);
        console.log("id", id);
        console.log("nf", nf);
        console.log("arquivonf", arquivonf);

        var headersm = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put('/osnfInsere', { status, id, nf, arquivonf, servicos, email }, headersm)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

    };



    const handleButtonClickEquipamento = async (statusEquipamento, nequipamento) => {

        console.log('Botão clicado!', statusEquipamento);

        var statusEqui;
        var idcliente;

        if (statusEquipamento === "inativo") {
            statusEqui = "ativo";
        }
        if (statusEquipamento === "ativo") {
            statusEqui = "inativo";
        }

        idcliente = cliente;


        await api.put('/ostemservicostatus', { statusEqui, idcliente, id, nequipamento }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    const handleButtonClickCliente = async () => {
        //e.preventDefault();

        console.log('Botão clicado!');

        var statusCli;
        var idcliente;

        if (statusCliente === "inativo") {
            statusCli = "ativo";
        }
        if (statusCliente === "ativo") {
            statusCli = "inativo";
        }

        idcliente = cliente;

        await api.put('/ostemservicostatus', { statusCli, idcliente, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    // var todosComPdfParte2;


    const fetchData = async () => {

        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response);
                        console.log("response.data.status", response.data.status);
                        handleCliente(response.data.instituicao_id);
                        setTipo(response.data.tipo);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);
                        verificaPdf(response.data.nfImagem);

                        setEmail(response.data.emailCliente);
                        setForma(response.data.forma);
                        setDataTreinamento(response.data.dataConclusao);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getColaboradoresVinculados = async () => {
            try {
                const response = await api.post("/os/ColaboradoresVinculados", { id }, headers);
                setInfoColaboradores(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        if (tipo === 'treinamento') {
            const getConteudoProgramatico = async () => {
                try {
                    const response = await api.post("/os/ConteudoProgramatico", { id }, headers);
                    setConteudoProgramatico(response.data);
                } catch (error) {
                    console.error("Erro ao obter opções de vinculo2:", error);
                }
            };

            getConteudoProgramatico();
        }




        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();
        getColaboradoresVinculados();


        console.log("forma", forma);
        console.log("dataTreinamento", dataTreinamento);
    };





    useEffect(() => {



        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response);
                        console.log("response.data.status", response.data.status);
                        handleCliente(response.data.instituicao_id);
                        setTipo(response.data.tipo);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);
                        verificaPdf(response.data.nfImagem);

                        setEmail(response.data.emailCliente);
                        setForma(response.data.forma);
                        setDataTreinamento(response.data.dataConclusao);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnicoPorEmpresa", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getColaboradoresVinculados = async () => {
            try {
                const response = await api.post("/os/ColaboradoresVinculados", { id }, headers);
                setInfoColaboradores(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        if (tipo === 'treinamento') {
            const getConteudoProgramatico = async () => {
                try {
                    const response = await api.post("/os/ConteudoProgramatico", { id }, headers);
                    setConteudoProgramatico(response.data);
                } catch (error) {
                    console.error("Erro ao obter opções de vinculo2:", error);
                }
            };

            getConteudoProgramatico();
        }


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();
        getServicos(areaId);
        getColaboradoresVinculados();

    }, [id, tipo]);

    useEffect(() => {
        // Verifica se nf não é null
        const hasPdfParte2 = nf !== null && nf !== undefined; // Verifica se nf não é null ou undefined
        setTodosComPdfParte2(hasPdfParte2);
    }, [nf]);

    // const handleButtonClickStatusServico = async (statusServico, idservico) => {

    //     //console.log('Botão clicado!', statusServico, idservico);

    //     var statusServ;
    //     var idcliente;

    //     if (statusServico === "aprovado") {
    //         statusServ = "reprovado";
    //     }
    //     if (statusServico === "reprovado") {
    //         statusServ = "aprovado";
    //     }

    //     idcliente = cliente;


    //     await api.put('/ostemservicostatus', { idcliente, id, statusServ, idservico }, headers)
    //         .then((response) => {
    //             // setRetorno({
    //             //     type: 'success',
    //             //     mensagem: response.data.mensagem
    //             // });
    //             window.location.reload();
    //         }).catch((err) => {
    //             if (err.response) {
    //                 setRetorno({
    //                     type: 'error',
    //                     mensagem: err.response.data.mensagem
    //                 });
    //             } else {
    //                 setRetorno({
    //                     type: 'error',
    //                     mensagem: "Erro: Tente novamente!"
    //                 });
    //             }
    //         });
    //     // window.location.reload();

    // };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', 
            });

            
            if (response.headers['content-type'] === 'application/pdf') {
                console.log("if que verifica arquivo", e);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(e);
                
            } else {
                console.log('O arquivo não é um PDF.');
               
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
        }
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setNf(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (nf !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', nf);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    function removeHtmlTags(html) {
        
        let text = html.replace(/<[^>]*>?/gm, '');

        
        text = text.replace(/&nbsp;/g, ' '); 
        text = text.replace(/&amp;/g, '&');  
        text = text.replace(/&quot;/g, '"'); 
        text = text.replace(/&apos;/g, "'"); 
        text = text.replace(/&lt;/g, '<');   
        text = text.replace(/&gt;/g, '>');   

        

        return text;
    }

    function formatarDataPorExtenso(cidade) {
        const data = new Date();
        const dia = data.getDate();
        const mes = [
            "janeiro", "fevereiro", "março", "abril", "maio", "junho",
            "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ][data.getMonth()];
        const ano = data.getFullYear();

        return `${cidade}, ${dia} de ${mes} de ${ano}`;
    }


    const VisualizarCertificado = async (e) => {

        const camposObrigatorios = [
            { nome: "Data de Conclusão", valor: dataTreinamento },
            { nome: "Forma de realização", valor: forma }
        ];

        const camposFaltando = camposObrigatorios.filter(campo =>
            campo.valor === undefined || campo.valor === "" || campo.valor === null
        );

        if (camposFaltando.length > 0) {
            const mensagemErro = camposFaltando.map(campo => `${campo.nome} é obrigatório.`).join("\n");
            if (!dataTreinamento || !forma) {
                let missingFields = [];

                if (!forma) missingFields.push("Forma de realização do Treinamento");
                if (!dataTreinamento) missingFields.push("Data de Conclusão do treinamento");


                setModalMessage(`Os seguintes campos estão faltando: ${missingFields.join(", ")}`);
                setIsModalOpen(true);
                return;
            }
            return;
        }
        e.preventDefault();

        const pageWidth = 871;
        const pageHeight = 624;

        try {
            // Converte as imagens necessárias para base64
            const backgroundImage = await getBase64ImageFromURL(certificado_vazio);
            const AssinaturaConvertida = await getBase64ImageFromURL(Assinatura);
            const AssinaturaConvertidaRoberta = await getBase64ImageFromURL(AssinaturaRoberta);
            const CertificadoFundo = await getBase64ImageFromURL(CertificadoFundoV2);
            const ConteudoProgramaticoFundo = await getBase64ImageFromURL(ConteudoProgramatico);
            const logo = await getBase64ImageFromURL(logoReduto);
            //const conteudoProgramaticoImagem = await getBase64ImageFromURL(conteudoProgramatico.imagemConteudoProgramatico); // Converte a imagem do conteúdo programático

            const dataFormatada = format(new Date(dataTreinamento), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });

            // Inicialize o array de páginas
            let pages = [];

            var nomeCliente = clienteOptions.find(x => x.id === cliente);

            console.log("nomeCliente", nomeCliente);

            const dataPorExtenso = formatarDataPorExtenso("Cidade Exemplo");

            pages.push(
                {
                    image: CertificadoFundo,
                    width: pageWidth,
                    height: pageHeight,
                    absolutePosition: { x: 0, y: 0 }
                },
                {
                    image: logo,
                    width: 180,
                    absolutePosition: { x: 650, y: 5 },
                    margin: [0, 0, 0, 0]
                },
                {
                    text: `Reconhecemos que a Instituição:`,
                    alignment: "center",
                    width: pageWidth,
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    absolutePosition: { x: 280, y: 180 } // Primeira linha de texto
                },
                {
                    text: `${nomeCliente.nome}, CNPJ: ${nomeCliente.cnpj}`,
                    alignment: "center",
                    width: pageWidth,
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    absolutePosition: { x: 250, y: 200 } // Segunda linha de texto, ajustada para ficar logo abaixo
                },
                {
                    text: `Está em conformidade com os requisitos estabelecidos pela RDC 611, de 9 de março de 2022, Art.15  - O serviço de radiologia diagnóstica ou intervencionista deve implementar Programa de Educação Permanente para toda a equipe, em conformidade com o disposto nesta Resolução e nas demais normativas aplicáveis.`,
                    alignment: "center",
                    width: pageWidth - 200, // Define uma largura limitada para o texto
                    bold: true,
                    fontSize: 20,
                    color: "#477f7f",
                    margin: [200, 220, 0, 0]
                    //vERSAO Bruno
                    // alignment: "right",
                    // width: pageWidth - 180, // Define uma largura limitada para o texto
                    // bold: true,
                    // fontSize: 20,
                    // color: "#477f7f",
                    // margin: [200, 220, 0, 0]
                },
                {
                    text: `${nomeCliente.cidadeestado}` + ', ' + dataFormatada, // Exibe a data abaixo do texto "Está em conformidade..."
                    alignment: "center",
                    width: pageWidth,
                    fontSize: 14,
                    color: "#477f7f",
                    absolutePosition: { x: 220, y: 450 } // Ajuste y conforme necessário para ficar logo abaixo do último texto
                },
                {
                    image: AssinaturaConvertida,
                    width: 180,
                    absolutePosition: { x: 280, y: 490 },
                    margin: [0, 0, 0, 0]
                },
                {
                    image: AssinaturaConvertidaRoberta,
                    width: 180,
                    absolutePosition: { x: 560, y: 520 },
                    margin: [0, 0, 0, 0]
                },
                {
                    text: '',
                    pageBreak: 'after'
                }
            );

            pages = pages.concat(
                infoColaboradores.map((colaborador, index) => {
                    const colaboradorPages = [
                        {
                            image: CertificadoFundo, // Imagem convertida para base64
                            width: pageWidth,
                            height: pageHeight,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        {
                            image: logo,
                            width: 180,
                            absolutePosition: { x: 650, y: 5 },
                            margin: [0, 0, 0, 0]
                        },
                        {
                            text: `Reconhecemos que:`,
                            alignment: "center",
                            width: pageWidth,
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            absolutePosition: { x: 280, y: 180 }
                        },
                        {
                            text: `${colaborador.nomefuncionario}, CPF: ${colaborador.cpffuncionario}`,
                            alignment: "center",
                            width: pageWidth,
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            absolutePosition: { x: 250, y: 200 }
                        },
                        {
                            text: `Certificamos que o Individuo Ocupacionalmente Exposto (IOE) acima identificado, que faz parte do corpo técnico do ${nomeCliente.nome} participou do treinamento ${colaborador.nomeTreinamento} realizado de forma ${forma} assíncrona, com carga horária de ${colaborador.duracaoTreinamento} horas`,
                            alignment: "center",
                            width: pageWidth - 200,
                            bold: true,
                            fontSize: 20,
                            color: "#477f7f",
                            margin: [200, 220, 0, 0]
                        },
                        {
                            text: `${nomeCliente.cidadeestado}, ${dataFormatada}`,
                            alignment: "center",
                            width: pageWidth,
                            fontSize: 14,
                            color: "#477f7f",
                            absolutePosition: { x: 220, y: 420 }
                        },
                        {
                            image: AssinaturaConvertida,
                            width: 180,
                            absolutePosition: { x: 280, y: 490 },
                            margin: [0, 0, 0, 0]
                        },
                        {
                            image: AssinaturaConvertidaRoberta,
                            width: 180,
                            absolutePosition: { x: 560, y: 520 },
                            margin: [0, 0, 0, 0]
                        }
                    ];

                    if (index < infoColaboradores.length - 1) {
                        colaboradorPages.push({ text: '', pageBreak: 'after' });
                    }

                    return colaboradorPages;
                }).flat() // Flateia o array para evitar um array de arrays
            );



            // Adiciona páginas para cada colaborador
            // pages = pages.concat(
            //     infoColaboradores.map((colaborador, index) => {
            //         return [
            //             {
            //                 image: CertificadoFundo, // Imagem convertida para base64
            //                 width: pageWidth,
            //                 height: pageHeight,
            //                 absolutePosition: { x: 0, y: 0 }
            //             },
            //             {
            //                 image: logo,
            //                 width: 180,
            //                 absolutePosition: { x: 650, y: 5 },
            //                 margin: [0, 0, 0, 0]
            //             },
            //             {
            //                 text: `Reconhecemos que:`,
            //                 alignment: "center",
            //                 width: pageWidth,
            //                 bold: true,
            //                 fontSize: 20,
            //                 color: "#477f7f",
            //                 absolutePosition: { x: 280, y: 180 } // Primeira linha de texto
            //             },
            //             {
            //                 text: `${colaborador.nomefuncionario}, CPF: ${colaborador.cpffuncionario}`,
            //                 alignment: "center",
            //                 width: pageWidth,
            //                 bold: true,
            //                 fontSize: 20,
            //                 color: "#477f7f",
            //                 absolutePosition: { x: 250, y: 200 } // Segunda linha de texto, ajustada para ficar logo abaixo
            //             },
            //             {
            //                 text: `Certificamos que o Individuo Ocupacionalmente Exposto (IOE) acima identificado, que faz parte do corpo técnico do ${nomeCliente.nome} participou do treinamento ${colaborador.nomeTreinamento} realizado de forma ${forma} assíncrona, com carga horária de ${colaborador.duracaoTreinamento} horas`,
            //                 alignment: "center",
            //                 width: pageWidth - 200, // Define uma largura limitada para o texto
            //                 bold: true,
            //                 fontSize: 20,
            //                 color: "#477f7f",
            //                 margin: [200, 220, 0, 0]
            //                 //VERSAO BRUNO
            //                 // alignment: "right",
            //                 // width: pageWidth - 180, // Define uma largura limitada para o texto
            //                 // bold: true,
            //                 // fontSize: 20,
            //                 // color: "#477f7f",
            //                 // margin: [200, 220, 0, 0]
            //             },
            //             {
            //                 text: `${nomeCliente.cidadeestado}` + ', ' + dataFormatada, // Exibe a data abaixo do texto "Está em conformidade..."
            //                 alignment: "center",
            //                 width: pageWidth,
            //                 fontSize: 14,
            //                 color: "#477f7f",
            //                 absolutePosition: { x: 220, y: 420 }// Ajuste y conforme necessário para ficar logo abaixo do último texto
            //             },
            //             {
            //                 image: AssinaturaConvertida,
            //                 width: 180, // Largura da imagem ajustável
            //                 //alignment: "center", // Centraliza a imagem
            //                 absolutePosition: { x: 280, y: 490 },
            //                 margin: [0, 0, 0, 0] // Espaçamento acima da imagem
            //             },
            //             {
            //                 image: AssinaturaConvertidaRoberta,
            //                 width: 180, // Largura da imagem ajustável
            //                 //: "right", // Centraliza a imagem
            //                 absolutePosition: { x: 560, y: 520 },
            //                 margin: [0, 0, 0, 0] // Espaçamento acima da imagem
            //             },
            //             ...(index < infoColaboradores.length - 1 ? [{ text: '', pageBreak: 'after' }] : [])
            //         ];
            //     }).flat() // Flateia o array para evitar um array de arrays
            // );

            // Adiciona uma página em branco antes da tabela
            pages.push({ text: '', pageBreak: 'after' });

            const marginLeft = 50;
            const marginRight = 50;
            const marginTop = 50;
            const marginBottom = 50;

            const conteudoProgramaticoComColunas = [
                {
                    // Tabela com cabeçalho e dados
                    table: {
                        widths: ['*', 'auto'], // Define a largura das colunas
                        body: [
                            // Cabeçalho da tabela
                            [
                                { text: 'Disciplina', bold: true, alignment: 'left', noWrap: true },
                                { text: 'Carga Horária (h)', bold: true, alignment: 'right', noWrap: true }
                            ],
                            // Dados dinâmicos da tabela
                            ...conteudoProgramatico.dadosConteudoProgramatico.map(item => ([
                                { text: removeHtmlTags(item.conteudo), alignment: 'left' },
                                { text: `${item.duracao} h`, alignment: 'right' }
                            ])),
                            // Rodapé da tabela
                            [
                                { text: 'Total de Carga Horária', bold: true, alignment: 'left' },
                                { text: `${conteudoProgramatico.dadosTreinamento.duracao} h`, bold: true, alignment: 'right' }
                            ]
                        ]
                    },
                    layout: 'lightHorizontalLines', // Define um layout de linhas horizontais leves
                    margin: [0, 10, 0, 10] // Margens para a tabela
                }
            ];

            // Configuração da página com a imagem de fundo e conteúdo
            const layoutComColunas = [
                {
                    image: ConteudoProgramaticoFundo,
                    width: pageWidth,
                    height: pageHeight,
                    absolutePosition: { x: 0, y: 0 }
                },
                {
                    image: logo,
                    width: 180,
                    absolutePosition: { x: 650, y: 5 },
                    margin: [0, 0, 0, 0]
                },
                {
                    stack: conteudoProgramaticoComColunas,
                    margin: [250, 80, 0, 0], // Margens personalizadas
                    width: pageWidth - marginLeft - marginRight // Largura total da tabela
                }
            ];


            if (conteudoProgramaticoComColunas.length > 0) {
                pages.push(layoutComColunas);
            }

            const dd = {
                pageSize: {
                    width: pageWidth,
                    height: pageHeight
                },
                content: pages.filter((item, index, array) =>
                    !(item.pageBreak === 'after' && index === array.length - 1) // Remove a última quebra de página desnecessária
                )
            };


            pdfMake.createPdf(dd).open();
        } catch (error) {
            console.error("Erro ao carregar a imagem:", error);
        }
    };






    const handlePagoChange = (event, itemId) => {
        setServicos((prevServicos) =>
            prevServicos.map((item) =>
                item.id === itemId ? { ...item, pago: event.target.checked } : item
            )
        );
    };


    //todosComPdfParte2 = servicos.every((item) => item.pdf_parte2 !== null);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Faturamento OS</h1>
                <hr />
                {retorno.type === "error" ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        <strong>{retorno.mensagem}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                ) : (
                    ""
                )}
                <form className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" disabled name="cliente" value={cliente} onChange={handleClienteChange} >
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label htmlFor="criador" className="form-label">
                                                    Criador da OS
                                                </label>
                                            </b>
                                            <input type="text" name="criadordaos" disabled className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)}  ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label className="form-label">Tipo:</label>
                                            </b>
                                            <input type="text" name="tipo" className="form-control" value={tipo} onChange={text => setTipo(text.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label htmlFor="datacriacao" className="form-label">
                                                    Data da criação do Orçamento
                                                </label>
                                            </b>
                                            <input type="date" name="datacriacao" disabled className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label htmlFor="datacriacao" className="form-label">
                                                    Data da visita
                                                </label>
                                            </b>
                                            <input type="date" name="dataVisita" disabled className="form-control" value={dataVisita} onChange={(e) => handleDataVisita(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label htmlFor="prazo" className="form-label">
                                                    Prazo da Os
                                                </label>
                                            </b>
                                            <input type="date" name="prazo" disabled className="form-control" value={prazoentrega} onChange={(e) => handleDataPrazo(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="tecnico" className="form-label">
                                                    Técnicos*:
                                                </label>
                                            </b>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}
                                                disabled
                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" disabled value={status} onChange={handleSelectChange}>
                                                <option value="Agendado">Agendado</option>
                                                <option value="Aguardando Agendamento">Aguardando Agendamento</option>
                                                <option value="Cancelado">Cancelado</option>
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em revisão">Em Revisão</option>
                                                <option value="Aguardando faturamento">Aguardando Faturamento</option>
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                                        </div>
                                        {tipo === 'treinamento' && (
                                            <>
                                                <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                                    <b>
                                                        <label htmlFor="datacriacao" className="form-label">
                                                            Data de conclusão do treinamento
                                                        </label>
                                                    </b>
                                                    <input
                                                        disabled
                                                        type="date"
                                                        name="dataTreinamento"
                                                        className="form-control"
                                                        value={dataTreinamento}
                                                        onChange={(e) => setDataTreinamento(e.target.value)}
                                                    ></input>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                                    <b>
                                                        <label htmlFor="inputForma" className="form-label">
                                                            Forma de realização:
                                                        </label>
                                                    </b>
                                                    <select
                                                        id="inputForma"
                                                        className="form-select"
                                                        name="forma"
                                                        disabled
                                                        value={forma}
                                                        onChange={formaSelectChange}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Presencial">Presencial</option>
                                                        <option value="Remoto">Remoto</option>
                                                        <option value="EAD">EAD</option>
                                                    </select>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                            <label for="nf" className="form-label">Selecione uma foto/pdf do NF</label>
                                            <input className="form-control" type="file" name="nf" id="nf" onChange={veirificaTipoArquivo} /><br /><br />

                                            {viewMostrarpdf === 1 && <>
                                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                                    Visualizar PDF
                                                </button>
                                            </>}

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                Detalhes
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Serviços</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {servicos.length > 0 && (
                                            <>
                                                {servicos.map((item) => {
                                                    if (item.idservico !== 44 && item.idservico !== 43 && item.idservico !== 45) {
                                                        const isRed = item.criadoaposorcamento === 1;
                                                        return (
                                                            <div className="col-md-4" key={item.id}>
                                                                <div className="card mb-4">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Área: {item.area}</h5>
                                                                        <h6 className="card-subtitle mb-2 text-muted">Serviço: {item.servico}</h6>
                                                                        <p>Status do serviço: {item.statusServico}</p>
                                                                        <p className="card-text">Descrição: {item.descricao}</p>
                                                                        {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                            <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                                        ) : (
                                                                            tipo === 'servico' && (
                                                                                <div>
                                                                                    {item.idequipamento !== null ? (
                                                                                        <>

                                                                                            <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                                            <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                                            <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                                            <p className="card-text">Modelo: {item.modelo}</p>
                                                                                            <p className="card-text">N° de série: {item.numserie}</p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        {tipo === 'treinamento' && (
                                                                            <div className="col-md-4">

                                                                                <button type="button" className="btn btn-success me-md-2" onClick={VisualizarCertificado}>Visualizar Certificados</button>

                                                                            </div>
                                                                        )}
                                                                        {/* <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 form-outline mb-4">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <button type="button" className="btn btn-primary p-2 me-md-2">Visualizar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <>
                                                                            <hr />
                                                                            <h5>Selecione qual serviço já foi Pago</h5>
                                                                            <i className={`fas fa-sack-dollar ${isRed ? 'text-danger' : 'text-success'}`} style={{ marginLeft: '10px' }}></i>
                                                                            <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={item.pago || false}
                                                                                    onChange={(event) => handlePagoChange(event, item.id)}
                                                                                    disabled={item.pago === 1}
                                                                                />
                                                                                <label style={{ marginLeft: '5px' }}>Pago</label>
                                                                            </div>

                                                                        </>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />
                            <Link to={"/os"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/os',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                            {todosComPdfParte2 ? (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-success me-md-2"
                                        onClick={SalvarCorrecaoOs}
                                    >
                                        Enviar para o Cliente
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-warning me-md-2"
                                        onClick={InsereNfOs}
                                    >
                                        Salvar NF
                                    </button>
                                </>


                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                    disabled={true}
                                >
                                    Enviar para o Cliente
                                </button>
                            )}
                        </div>
                    </div>
                </form>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Erro de Validação"
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Erro de Validação</h5>
                            <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalMessage}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(false)}>
                                Fechar
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}