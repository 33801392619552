import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const SideBar = () => {
    const permissao = JSON.parse(localStorage.getItem('permissao'));
    const tipo = localStorage.getItem('tipo');

    //console.log("permissa", permissao);

    //Versão Nova
    // const [menuLateral] = useState([
    //     { permissao_id: 26, permissao: "visualizarMenuEquipamentoInterno",titulo: "Equipamento Interno" ,url: "/equipamentointerno" },
    //     { permissao_id: 1, permissao: "visualizarMenuPermissao",titulo: "Permissão" ,url: "/permission" }, 
    //     { permissao_id: 6, permissao: "visualizarMenuArea" , titulo: "Área" , url: "/area" }, 
    //     { permissao_id: 11, permissao: "visualizarMenuNivel", titulo: "Nivel" , url: "/niveis" }, 
    //     { permissao_id: 16, permissao: "visualizarMenuUsuario", titulo: "Usuário" , url: "/usuarios" }, 
    //     { permissao_id: 21, permissao: "visualizarMenuEmpresa", titulo: "Empresa", url: "/empresa" },
    //     { permissao_id: 31, permissao: "visualizarMenuCliente",titulo: "Cliente" ,url: "/cliente" }
    // ]);

    // const [menuLateralAux] = useState([
    //     { permissao_id: 26, permissao: "visualizarMenuEquipamentoInterno",titulo: "Equipamento Interno" ,url: "/equipamentointerno" },
    //     { permissao_id: 1, permissao: "visualizarMenuPermissao",titulo: "Permissão" ,url: "/permission" }, 
    //     { permissao_id: 6, permissao: "visualizarMenuArea" , titulo: "Área" , url: "/area" }, 
    //     { permissao_id: 11, permissao: "visualizarMenuNivel", titulo: "Nivel" , url: "/niveis" }, 
    //     { permissao_id: 16, permissao: "visualizarMenuUsuario", titulo: "Usuário" , url: "/usuarios" }, 
    //     { permissao_id: 21, permissao: "visualizarMenuEmpresa", titulo: "Empresa", url: "/empresa" },
    //     { permissao_id: 31, permissao: "visualizarMenuCliente",titulo: "Cliente" ,url: "/cliente" }
    // ]);


    const [menuLateralUsuarios] = useState([
        { permission_id: 29, permissao: "visualizarMenuEquipamentoInterno", titulo: "Equipamento Interno", url: "/equipamentointerno" },
        { permission_id: 2, permissao: "visualizarMenuPermissao", titulo: "Permissão", url: "/permission" },
        { permission_id: 9, permissao: "visualizarMenuArea", titulo: "Área", url: "/area" },
        { permission_id: 14, permissao: "visualizarMenuNivel", titulo: "Nivel", url: "/niveis" },
        { permission_id: 19, permissao: "visualizarMenuUsuario", titulo: "Colaboradores", url: "/usuarios" },
        { permission_id: 24, permissao: "visualizarMenuEmpresa", titulo: "Empresa", url: "/empresa" },
        { permission_id: 36, permissao: "visualizarMenuCliente", titulo: "Cliente", url: "/cliente" }
    ]);

    const [menuLateralAux] = useState([
        { permission_id: 29, permissao: "visualizarMenuEquipamentoInterno", titulo: "Equipamento Interno", url: "/equipamentointerno" },
        { permission_id: 2, permissao: "visualizarMenuPermissao", titulo: "Permissão", url: "/permission" },
        { permission_id: 9, permissao: "visualizarMenuArea", titulo: "Área", url: "/area" },
        { permission_id: 14, permissao: "visualizarMenuNivel", titulo: "Nivel", url: "/niveis" },
        { permission_id: 19, permissao: "visualizarMenuUsuario", titulo: "Colaboradores", url: "/usuarios" },
        { permission_id: 24, permissao: "visualizarMenuEmpresa", titulo: "Empresa", url: "/empresa" },
        { permission_id: 36, permissao: "visualizarMenuCliente", titulo: "Cliente", url: "/cliente" }
    ]);

    const [menuLateralOs] = useState([
        { permission_id: 52, permissao: "visualizarMenuOrcamento", titulo: "Orçamento", url: "/orcamento" },
        { permission_id: 47, permissao: "visualizarMenuServico", titulo: "Serviço", url: "/servico" },
        { permission_id: 63, permissao: "visualizarMenuOs", titulo: "OS", url: "/os" },
    ]);

    const [menuLateralAuxOs] = useState([
        { permission_id: 52, permissao: "visualizarMenuOrcamento", titulo: "Orçamento", url: "/orcamento" },
        { permission_id: 47, permissao: "visualizarMenuServico", titulo: "Serviço", url: "/servico" },
        { permission_id: 63, permissao: "visualizarMenuOs", titulo: "OS", url: "/os" },
    ]);

    if (tipo === "sistema" && permissao != null && permissao.length > 0) {
        permissao.forEach(z => {
            var retornoForEach = menuLateralAux.findIndex(x => {
                return x.permission_id === z.permission_id
            });

            if (retornoForEach > -1) {
                menuLateralAux.splice(retornoForEach, 1)
            }
        })

        menuLateralAux.forEach(z => {
            var retornoForEach = menuLateralUsuarios.findIndex(x => {
                return x.permission_id === z.permission_id
            });

            if (retornoForEach > -1) {
                menuLateralUsuarios.splice(retornoForEach, 1)
            }
        })

        permissao.forEach(z => {
            var retornoForEach = menuLateralAuxOs.findIndex(x => {
                return x.permission_id === z.permission_id
            });

            if (retornoForEach > -1) {
                menuLateralAuxOs.splice(retornoForEach, 1)
            }
        })

        menuLateralAuxOs.forEach(z => {
            var retornoForEach = menuLateralOs.findIndex(x => {
                return x.permission_id === z.permission_id
            });

            if (retornoForEach > -1) {
                menuLateralOs.splice(retornoForEach, 1)
            }
        })

    }

    //const permissionid = [2, 9, 14, 19, 24];

    //const filterPermissions = menuLateral.filter(x => permissao.includes(x.permission_id));

    //console.log("filterPermissions", filterPermissions);



    return (
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            {/* <Link className="nav-link" to="/dashboard">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </Link> */}
                            {/* {tipo === "sistema" && permissao != null && permissao.length > 0 &&
                                <>
                                    {menuLateralUsuarios.length > 0 &&
                                        <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#Usuarios" aria-expanded="false" aria-controls="Usuarios">
                                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                            Usuários
                                            <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </a>
                                    }
                                    <div className="collapse" id="Usuarios" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                        <nav className="sb-sidenav-menu-nested nav">
                                            {permissao != null && permissao.length > 0 &&
                                                menuLateralUsuarios?.map((item) => (
                                                    <Link key={item.permission_id} className="nav-link" to={item.url}>{item.titulo}</Link>
                                                ))
                                            }
                                        </nav>
                                    </div>
                                </>
                            } */}
                            {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#OS" aria-expanded="false" aria-controls="OS">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Os
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a> */}
                            {/* <div className="collapse" id="OS" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                {tipo === "sistema" && permissao != null && permissao.length > 0 &&
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/orcamento">Orçamento</Link>
                                    <Link className="nav-link" to="/servico">Serviço</Link>
                                    <Link className="nav-link" to="/os">Os</Link>
                                        {permissao != null && permissao.length > 0 &&
                                            menuLateralOs.map((item) => (
                                                <Link key={item.permission_id} className="nav-link" to={item.url}>{item.titulo}</Link>
                                            ))
                                        }
                                    </nav>
                                }
                                {tipo === "cliente" &&
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/oscliente">Os</Link>
                                    </nav>
                                }
                                <Link className="nav-link" to="/usuarios">Usuários</Link>
                                    <Link className="nav-link" to="/niveis">Níveis</Link>
                                    <Link className="nav-link" to="/area">Area</Link>
                                    <Link  className="nav-link" to="/permission">Permissão</Link>
                                    <Link className="nav-link" to="/empresa">Empresa</Link>
                            </div> */}
                            {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#TREINAMENTOS" aria-expanded="false" aria-controls="TREINAMENTOS">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Treinamentos
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="TREINAMENTOS" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/treinamentos">Treinamentos</Link>
                            </div> */}
                            {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#FINANCEIRO" aria-expanded="false" aria-controls="FINANCEIRO">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Financeiro
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="FINANCEIRO" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/financeiro/orcamentoAprovado">Financeiro</Link>
                                <Link className="nav-link" to="/financeiro/faturamentoDetalhado">Faturamento Detalhado</Link>
                            </div> */}

                            {/* Dashboard  */}
                            {/* -> Panorama
                            ->Vendas/Financeiro
                            -> Técnico/Revisor */}

                            <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#Dashboard" aria-expanded="false" aria-controls="Dashboard">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="Dashboard" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/dashboardTecnicoRevisor">Técnico/Revisor</Link>
                                <Link className="nav-link" to="/dashboardVendasFinanceiro">Vendas/Financeiro</Link>
                                <Link className="nav-link" to="/dashboard">Panorama</Link>
                            </div>

                            {/* Usuários
                            Colaboradores
                            Clientes
                            Parceiros Comerciais */}

                            <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#Usuarios" aria-expanded="false" aria-controls="Usuarios">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Usuários
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="Usuarios" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/usuarios">Colaboradores</Link>
                                <Link className="nav-link" to="/cliente">Clientes</Link>
                                <Link className="nav-link" to="/parceiros">Parceiros</Link>
                                {/* <Link className="nav-link" to="/parceirostecnicos">Parceiros Técnicos</Link> */}
                            </div>

                            {/* Orçamento (OR)
                            OR
                            OR Parceiros */}

                            <Link className="nav-link" to="/orcamento">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Orçamento (OR)
                            </Link>

                            {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#OR" aria-expanded="false" aria-controls="OR">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Orçamento (OR)
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a> */}
                            {/* <div className="collapse" id="OR" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/orcamento">OR</Link>
                                <Link className="nav-link" to="/orparceiros">OR Parceiros</Link>
                            </div> */}

                            {/* Serviços (OS)
                            OS */}
                            <Link className="nav-link" to="/os">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Serviços (OS)
                            </Link>

                            {/* Administrativo
                            Minha Empresa
                            Equipamentos Interno
                            Treinamentos
                            Financeiro
                            Financeiro Detalhado */}

                            <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#ADMINISTRATIVO" aria-expanded="false" aria-controls="ADMINISTRATIVO">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Administrativo
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="ADMINISTRATIVO" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/empresa">Minha Empresa</Link>
                                <Link className="nav-link" to="/equipamentointerno">Equipamentos Interno</Link>
                                <Link className="nav-link" to="/treinamentos">Treinamentos</Link>
                                <Link className="nav-link" to="/financeiro/orcamentoAprovado">Financeiro</Link>
                                <Link className="nav-link" to="/financeiro/faturamentoDetalhado">Financeiro Detalhado</Link>
                            </div>

                            {/* Sistema
                            Permissões de nível
                            Nível do usuário
                            Área de Prestação
                            Cadastro de Prestação */}

                            <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#SISTEMA" aria-expanded="false" aria-controls="SISTEMA">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Sistema
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="SISTEMA" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <Link className="nav-link" to="/permission">Permissão de nivel</Link>
                                <Link className="nav-link" to="/niveis">Nível do usuário</Link>
                                <Link className="nav-link" to="/area">Área de Prestação</Link>
                                <Link className="nav-link" to="/servico">Cadastro de Prestação</Link>
                            </div>








                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Criado por:</div>
                        Reduto Ion
                    </div>
                </nav>
            </div>

        </div>
    );
};